import { addDoc, collection, doc, runTransaction, Timestamp } from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import {
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../../Drivers/setPolicyInfo";
import { selectIfNewOrExistingSubcollection } from "../../../utils/validateIfBlacklisted";
import { multipleToEndorsements } from "../../Drivers/multipleToEndorsements";

const submitAddTruckEndorsement = async (
  e,
  policyID,
  truckData,
  insuredInfo,
  userInfo,
  setSuccess,
  setTruckData,
  endorsementEffectiveDate,
  formData,
  goBack,
  isEndoManager,
  getEndorsements,
  documentTypeToUse,
  renewalProcess,
  mirroredIds,
  originalPolicyID,
  truckList
) => {
  e?.preventDefault();
  let endoEffectiveDate;
  if (documentTypeToUse === "Policy" || renewalProcess) {
    endoEffectiveDate = endorsementEffectiveDate;
  } else {
    endoEffectiveDate = "";
  }

  if (truckData?.truckVIN?.length !== 17)
    return alert("VIN must be 17 characters.");
  if (truckData?.truckYear?.length !== 4)
    return alert("Year must be 4 characters.");
  if (truckData?.truckACV === "0")
    return alert("Actual Cash Value must be greater than $0.00");

  const truckAlreadyExists = truckList.find(
    (truck) => truck.truckVIN === truckData.truckVIN
  );
  if (truckAlreadyExists && !mirroredIds) {
    goBack(e);
    return alert(
      `A truck with the VIN ${truckData.truckVIN} is already active in the policy.`
    );
  } else {
    const { agencyID, insuredID } = formData;
    const payloadResult = setPolicyInfo(
      {
        ...truckData,
        dateCreated: new Date(),
      },
      {
        ...formData,
        policyEffectiveDate: formData?.effectiveDate,
        glPolicyNo: formData?.glPolicyNo,
        alPolicyNo: formData?.alPolicyNo,
        policyID,
        status:
          !renewalProcess && documentTypeToUse === "Application"
            ? "Active"
            : "Pending",
        documentType: documentTypeToUse,
        policyStatus: formData?.status,
        agencyID,
        insuredID,
      },
      !renewalProcess && documentTypeToUse === "Application"
        ? "direct-addition"
        : "endo-addition"
    );

    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "power-units"
    );

    if (documentTypeToUse === "Policy") {
      const payload = {
        ...payloadResult,
        id: response.id,
      };
      const payloadForEndo = setPolicyInfoForEndorsement(payload, policyID);
      if (mirroredIds) {
        payloadForEndo["mirroredIds"] = mirroredIds;
        payloadForEndo["mirroredPolicyId"] = formData.renewalApplicationID
          ? formData.renewalApplicationID
          : originalPolicyID;
      }
      await multipleToEndorsements(
        policyID,
        endoEffectiveDate,
        "Add Power Units",
        [payloadForEndo],
        insuredInfo,
        formData,
        userInfo,
        getEndorsements,
        [],
        null,
        null,
        null
      );
    }

    let mirroredIdsResult = [];
    if (renewalProcess && !mirroredIds) {
      mirroredIdsResult.push(response.id);
    }

    updateSystemLog(
      auth,
      policyID,
      "Power Unit (" +
        truckData.truckVIN +
        ") Added To Application For " +
        insuredInfo.company +
        " by " +
        auth?.currentUser?.displayName,
      "Power Unit Added",
      truckData
    );
    setTruckData({});
    goBack(e);
    return mirroredIdsResult;
  }
};

export default submitAddTruckEndorsement;