import { Routes, Route } from "react-router-dom";
import { AuthContextProvider } from "../Context/AuthContent";
import ProtectedRoute from "./ProtectedRoute";
import Signin from "../pages/Signin";
import Signup from "../pages/SignUp";
import Account from "../pages/Account";
import ChangePassword from "../pages/ChangePassword";
import SetProfile from "../pages/EditProfile";
import ApplicationForm from "../pages/PolicyLifecycleManager";
import NewApplication from "../pages/NewApplication";
import Endorsements from "../pages/Endorsements";
import Help from "../pages/Help";
import { Endorsement } from "../pages/Endorsement";
import ManagePolicies from "../pages/ManagePolicies";
import ManageUsers from "../pages/ManageUsers";
import ManageApplications from "../pages/ManageApplications";
import AgencyProfile from "../pages/AgencyProfile";
import NewSupportTicket from "../pages/NewSupportRoute";
import SupportTicketsList from "../pages/NewSupportRoute/SupportTicketsList";
import LossRunRequest from "../pages/LossRuns";
import LossRunsList from "../pages/LossRuns/LossRunsList";
import Calendar from "../pages/Calendar";
import {
  ACCOUNT_ROUTE,
  ADMIN_ROUTE,
  AGENT_MANAGE_NOTIFICATIONS_ROUTE,
  AGENT_COMPANY_PROFILE_ROUTE,
  AGENT_MANAGE_APPLICATIONS_ROUTE,
  AGENT_MANAGE_POLICIES_ROUTE,
  AGENT_MANAGE_USERS_ROUTE,
  AGENT_MANAGE_CLIENTS_ROUTE,
  APPLICATION_ID_ROUTE,
  HOME_ROUTE,
  NEW_APPLICATION_ID_ROUTE,
  PASSWORD_ROUTE,
  POLICY_REF_ROUTE,
  SIGN_UP_ROUTE,
  SIGN_IN_ROUTE,
  UNDERWRITING_ENDORSEMENT_REF_ID_ROUTE,
  UNDERWRITING_ENDORSEMENT_ROUTE,
  USER_DETAILS_ROUTE,
  OLD_AGENT_DIRECTORY_ROUTE,
  AGENT_DIRECTORY_ROUTE,
  AGENT_PROFILE_REF_ROUTE,
  APPOINTMENT_APPLICATION_REF_ROUTE,
  CHANGE_LOG_ROUTE,
  CONTRACT_REF_ROUTE,
  FILING_LOG_ROUTE,
  LETTER_LOG_ROUTE,
  LOSS_RUNS_ROUTE,
  NEW_SUPPORT_TICKET_ROUTE,
  PROPOSAL_REF_COMPANY_ROUTE,
  SCT_ROUTE,
  SEND_LETTERS_ROUTE,
  SUBMIT_BUSINESS_REF_SUBMISSION_ROUTE,
  SYSTEM_LOG_ROUTE,
  UNDERWRITER_DIRECTORY_ROUTE,
  VIEW_APPLICATION_REF_ROUTE,
  W9_REF_ROUTE,
  SUPPORT_TICKETS_LIST_ROUTE,
  LOSS_RUNS_LIST_ROUTE,
  LOSS_CONTROL_ROUTE,
  BLACKLIST_ROUTE,
  CALENDAR_ROUTE,
  HELP_ROUTE,
  DRIVERS_TRAININGS_ROUTE,
  CLIENT_ROUTE,
  SUBMIT_BUG_ROUTE,
  VIEW_BUGS_ROUTE,
  FINANCES_ROUTE,
  CONVERTER_ROUTE,
  TEMPORARILY_REF_ROUTE,
  ABSENCE_ROUTE,
  LICONA_SIGN_IN_ROUTE,
  PROOF_OF_FILINGS_ROUTE,
  MAINTENANCE_ROUTE,
  TEMPORARILY_CLIENT_REF_ROUTE,
  LOSS_CONTROL_REPORT_ROUTE,
} from "../utils/routes";
import AgentDirectory from "../Components/directories/AgentDirectory";
import AgentProfile from "../Components/agentAppointment/AgentProfile";
import SubmitBusiness from "../Components/toRetireOrReplace/submissions/SubmitBusiness";
import Contract from "../Components/agentAppointment/appointment/Contract";
import W9 from "../Components/agentAppointment/appointment/W9";
import UnderwriterDirectory from "../Components/directories/UnderwriterDirectory";
import ChangeLog from "../Components/logs/ChangeLog";
import SystemLog from "../Components/logs/SystemLog";
import LetterLog from "../Components/logs/LetterLog";
import Proposal from "../Components/toRetireOrReplace/proposals/Proposal";
import FilingLog from "../Components/logs/FilingLog";
import SendLetterPanel from "../Components/toRetireOrReplace/letters/SendLetterPanel";
import SCT from "../Components/toRetireOrReplace/SCT";
import AppointmentApplication from "../Components/agentAppointment/appointment/AppointmentApplication";
import "../Components/styling/app.css";
import { USER_INFO_ROLE } from "../utils/constants";
import SignUpFullData from "../pages/SignUp/SignUpFullData";
import Admin from "../pages/Admin";
import ManageNotifications from "../pages/ManageNotifications";
import LossControl from "../pages/LossControl";
import Appointment from "../Components/agent/AppointmentApplication";
import DriversBlacklist from "../Components/DriversBlacklist";
import PermissionValidator from "./PermissionValidator";
import NotFound from "./NotFound";
import DriverTrainingDashboard from "../Components/DriversTrainings";
import SubmitReport from "../Components/BugReports/SubmitReport";
import ViewReports from "../Components/BugReports/ViewReports";
import Finances from "../pages/Finances";
import Converter from "../pages/Converter";
import NotLoggedInModules from "../pages/PolicyLifecycleManager/NotLoggedInModules/Panel";
import LoggedInModules from "../pages/PolicyLifecycleManager/LoggedInModules/Panel";
import AbsenceForm from "../Components/Tracker/AbsenceForm";
import LiconaSignin from "../pages/LiconaSignin";
import ProofOfFilings from "../pages/ProofOfFiliings";
import Maintenance from "./Maintenance";
import Client from "../Components/Client";
import AgenciesList from "../Components/agentAppointment/AgenciesList";
import ManageClients from "../pages/ManageClients";
import LossControlReport from "../Components/LossControl/LossControlReport";

function Router({ userInfo, loading, setLoading, noProfile, setNoProfile }) {
  const { ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE, CLIENT_ROLE } = USER_INFO_ROLE;

  return (
    <AuthContextProvider>
      <Routes>
        <Route
          path={HOME_ROUTE}
          element={
            <Signin
              changeAuthStatus={(val) => this.setState({ isLoggedIn: val })}
            />
          }
        />
        <Route
          path={SIGN_IN_ROUTE}
          element={
            <Signin
              changeAuthStatus={(val) => this.setState({ isLoggedIn: val })}
            />
          }
        />
        <Route
          path={LICONA_SIGN_IN_ROUTE}
          element={
            <LiconaSignin
              changeAuthStatus={(val) => this.setState({ isLoggedIn: val })}
            />
          }
        />
        <Route
          path={LOSS_RUNS_ROUTE}
          element={
            <ProtectedRoute>
              <LossRunRequest userInfo={userInfo} />
            </ProtectedRoute>
          }
        />
        <Route
          path={LOSS_RUNS_LIST_ROUTE}
          element={
            <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
              <LossRunsList />
            </PermissionValidator>
          }
        />
        <Route path={SIGN_UP_ROUTE} element={<SignUpFullData />} />
        <Route path={ABSENCE_ROUTE} element={<AbsenceForm />} />
        <Route
          path={PASSWORD_ROUTE}
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path={NEW_APPLICATION_ID_ROUTE}
          element={
            <ProtectedRoute>
              <NewApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path={ADMIN_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE]}>
                <Admin userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={CLIENT_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, CLIENT_ROLE]}>
                <Client userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_MANAGE_NOTIFICATIONS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ManageNotifications userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_COMPANY_PROFILE_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <AgencyProfile userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_MANAGE_POLICIES_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ManagePolicies
                  loading={loading}
                  setLoading={setLoading}
                  userInfo={userInfo}
                />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={FINANCES_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <Finances
                  loading={loading}
                  setLoading={setLoading}
                  userInfo={userInfo}
                />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={PROOF_OF_FILINGS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ProofOfFilings
                  loading={loading}
                  setLoading={setLoading}
                  userInfo={userInfo}
                />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={CONVERTER_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE]}>
                <Converter
                  loading={loading}
                  setLoading={setLoading}
                  userInfo={userInfo}
                />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_MANAGE_APPLICATIONS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ManageApplications
                  loading={loading}
                  setLoading={setLoading}
                  userInfo={userInfo}
                />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_MANAGE_USERS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ManageUsers userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_MANAGE_CLIENTS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE, CLIENT_ROLE]}
              >
                <ManageClients userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />

<Route
          path={LOSS_CONTROL_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <LossControl userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={LOSS_CONTROL_REPORT_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <LossControlReport userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={BLACKLIST_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <DriversBlacklist userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={DRIVERS_TRAININGS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <DriverTrainingDashboard />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={UNDERWRITING_ENDORSEMENT_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <Endorsements userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={UNDERWRITING_ENDORSEMENT_REF_ID_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <Endorsement />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={APPOINTMENT_APPLICATION_REF_ROUTE}
          element={
            <ProtectedRoute>
              <AppointmentApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/appointment/:paramRefID"}
          element={
            <ProtectedRoute>
              <Appointment />
            </ProtectedRoute>
          }
        />
        <Route
          path={APPLICATION_ID_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ApplicationForm />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={POLICY_REF_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ApplicationForm />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={TEMPORARILY_REF_ROUTE}
          element={
            <ProtectedRoute>
              <NotLoggedInModules />
            </ProtectedRoute>
          }
        />
        <Route
          path={TEMPORARILY_CLIENT_REF_ROUTE}
          element={
            <ProtectedRoute>
              <LoggedInModules />
            </ProtectedRoute>
          }
        />
        <Route
          path={SUBMIT_BUSINESS_REF_SUBMISSION_ROUTE}
          element={
            <ProtectedRoute>
              <SubmitBusiness />
            </ProtectedRoute>
          }
        />
        <Route
          path={VIEW_APPLICATION_REF_ROUTE}
          element={
            <ProtectedRoute>
              <AppointmentApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path={SEND_LETTERS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <SendLetterPanel />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={CONTRACT_REF_ROUTE}
          element={
            <ProtectedRoute>
              <Contract />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCT_ROUTE}
          element={
            <ProtectedRoute>
              <SCT />
            </ProtectedRoute>
          }
        />
        <Route
          path={W9_REF_ROUTE}
          element={
            <ProtectedRoute>
              <W9 />
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_PROFILE_REF_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <AgentProfile />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={ACCOUNT_ROUTE}
          element={
            <ProtectedRoute>
              <Account
                userInfo={userInfo}
                noProfile={noProfile}
                setNoProfile={setNoProfile}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path={USER_DETAILS_ROUTE}
          element={
            <ProtectedRoute>
              <SetProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path={AGENT_DIRECTORY_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <AgenciesList />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={AGENT_DIRECTORY_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <AgentDirectory />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={UNDERWRITER_DIRECTORY_ROUTE}
          element={
            <ProtectedRoute>
              <UnderwriterDirectory />
            </ProtectedRoute>
          }
        />

        <Route path={NEW_SUPPORT_TICKET_ROUTE} element={<NewSupportTicket />} />

        <Route
          path={SUPPORT_TICKETS_LIST_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <SupportTicketsList userInfo={userInfo} />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />

        <Route
          path={CHANGE_LOG_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <ChangeLog />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />

        <Route
          path={SUBMIT_BUG_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <SubmitReport />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={VIEW_BUGS_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator
                validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE, AGENT_ROLE]}
              >
                <ViewReports />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />

        <Route
          path={LETTER_LOG_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <LetterLog />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={FILING_LOG_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <FilingLog />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={SYSTEM_LOG_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <SystemLog />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={PROPOSAL_REF_COMPANY_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE, UNDERWRITER_ROLE]}>
                <Proposal />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={CALENDAR_ROUTE}
          element={
            <ProtectedRoute>
              <PermissionValidator validRoles={[ADMIN_ROLE]}>
                <Calendar />
              </PermissionValidator>
            </ProtectedRoute>
          }
        />
        <Route
          path={HELP_ROUTE}
          element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          }
        />
        <Route
          path={MAINTENANCE_ROUTE}
          element={
            <ProtectedRoute>
              <Maintenance />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthContextProvider>
  );
}

export default Router;
