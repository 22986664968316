import {
  addDoc,
  collection,
  doc,
  runTransaction,
  Timestamp,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import updateSystemLog from "../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import { setPolicyInfoForEndorsement } from "./setPolicyInfo";
import { convertISOToFirestoreFormat } from "../../../utils/helpers";

export const buildDocumentPath = (type, id, formData) => {
  let initialPath
  if (type.includes("Driver")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/drivers/${id}`;
  } else if (type.includes("Power Unit") || type.includes("Radius")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/power-units/${id}`;
  } else if (type.includes("Trailer")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/trailers/${id}`;
  }
  if (!initialPath) return null;
  return doc(db, initialPath)
};

export const getDocumentPaths = (dataArray, type, formData) => {
  return dataArray
    .map(data => {
      const id = data?.id;
      return buildDocumentPath(type, id, formData);
    })
    .filter(path => path !== null);
};

export const multipleToEndorsements = async (
  policyID,
  endoEffectiveDate,
  type,
  dataArray,
  insuredInfo,
  formData,
  userInfo,
  getEndorsements,
  endorsements,
  deletionReason,
  proofOfPowerDeletion,
  blacklistExpirationDate
) => {
  try {
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const transactionResult = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });

      const endosRef = collection(
        db,
        "policy-applications",
        policyID,
        "endorsements"
      );
      
      const docPaths = getDocumentPaths(dataArray, type, formData);
      const effectiveDate = convertISOToFirestoreFormat(endoEffectiveDate)

      const status = "Submitted";
      const payload = {
        endorsementNumber: String(newCounter),
        endoEffectiveDate: effectiveDate,
        policyID,
        type,
        dateCreated: Timestamp.fromDate(new Date()),
        status,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        alPolicyNo: formData.alPolicyNo,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        documentSource: 'Endorsement',
      };
      if (deletionReason) {
        payload['deletionReason'] = deletionReason
      }
      if (proofOfPowerDeletion) {
        payload['proofOfPowerDeletion'] = proofOfPowerDeletion
      }
      if (blacklistExpirationDate) {
        payload['blacklistExpirationDate'] = blacklistExpirationDate
      }
      const endorsementMatchFound = endorsements?.some(endorsement => {
        return Boolean(endorsement?.type === type)
      });
      let docRef;
      if (
        endorsementMatchFound &&
        endorsementMatchFound?.status === status 
      ) {
        return `Approve or decline your old endorsement "${type}"`
      } else {
        const data = setPolicyInfoForEndorsement(payload, policyID)
        data['data'] = docPaths
        docRef = await addDoc(endosRef, data);
      }
      endoStatusChangeNotification(
        type,
        String(newCounter),
        status,
        docRef.id,
        policyID,
        userInfo.agencyID
      );
      const VINs = dataArray.map(
        (data) => Object.keys(data).find((key) => key.includes("VIN")) || ""
      );
      await updateSystemLog(
        auth,
        policyID,
        `${type} Endorsements (${VINs.join(", ")}) Submitted For ${
          insuredInfo.company
        } by ${auth.currentUser.displayName}`,
        `${type} Endorsement`,
        dataArray
      );
      await getEndorsements();
      return ''
    });
    return transactionResult
  } catch (error) {
    throw error;
  }
};