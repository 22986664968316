import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import calculatePremiums from "../../../utils/calculatePremiums";
import { db } from "../../../../firebase";
import requestData from "./requestData";
import miniValidation from "../../../utils/premiumCalculation/miniValidation";
import ApiService from "../../../../services/southern-api/apiService";
import getTaxSchedule from "../../../utils/getTaxSchedule";
import findTotalAdjustments from "./findTotalAdjustments";

const apiService = new ApiService("southern");

const createNewQuote = async (
  e,
  isReprint,
  setLoading,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setShowQuoteSuccess,
  formData,
  insuredInfo,
  auth,
  formDataGL
) => {
  e.preventDefault();
  setLoading(true);
  // log all props
  // console.log("isReprint: ", isReprint);
  // console.log("setLoading: ", setLoading);
  // console.log("adjustmentFormData: ", adjustmentFormData);
  // console.log("setAdjustmentFormData: ", setAdjustmentFormData);
  // console.log("truckListData: ", truckListData);
  // console.log("setShowQuoteSuccess: ", setShowQuoteSuccess);
  // console.log("formData: ", formData);
  // console.log("insuredInfo: ", insuredInfo);

  

  // Initial checks and logging
  try {
    console.log("Is reprint: ", isReprint);
    if (!formData || !adjustmentFormData || !truckListData) {
      throw new Error("Missing required form data.");
    }

    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    const policyID = formData.id;

    const totalAdjustment = findTotalAdjustments({adjustmentFormData});

    const truckList = truckListData[1];
    miniValidation(truckList, formData);

    const underwritingValidation = () => {
      if (formData.hasMTCCoverage) {
        if (!formData.mtcCarrier || formData.mtcCarrier === "") return false;
        if (
          !formData.mtcDetails.generalAggregateLimit ||
          formData.mtcDetails.generalAggregateLimit === ""
        )
          return false;
        if (
          !formData.mtcDetails.generalAggregateDeductible ||
          formData.mtcDetails.generalAggregateDeductible === ""
        )
          return false;
        if (
          !formData.mtcDetails.totalPremiumBeforeTaxes ||
          formData.mtcDetails.totalPremiumBeforeTaxes === ""
        )
          return false;
      }
      if (formData.hasAPDCoverage) {
        if (!formData.apdCarrier || formData.apdCarrier === "") return false;
        if (
          !formData.apdDetails.generalAggregateLimit ||
          formData.apdDetails.generalAggregateLimit === ""
        )
          return false;
        if (
          !formData.apdDetails.generalAggregateDeductible ||
          formData.apdDetails.generalAggregateDeductible === ""
        )
          return false;
        if (
          !formData.apdDetails.totalPremiumBeforeTaxes ||
          formData.apdDetails.totalPremiumBeforeTaxes === ""
        )
          return false;
      }
      return true;
    };

    // TODO: Fix this validation
    // if (!underwritingValidation()) {
    //   alert("Missing required underwriting data.");
    //   throw new Error("Missing required underwriting data.");
    // }

    let driverList = [];
    const driverSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "drivers")
    );
    driverSnapshot.forEach((doc) => {
      driverList.push({ ...doc.data(), id: doc.id });
    });

    const allPremiums = await calculatePremiums(
      policyID,
      formData,
      e,
      "#",
      totalAdjustment,
      isReprint ? true : false,
      false,
      null,
      formDataGL
    );

//     console.log("Policy ID: ", policyID);
// console.log("Form Data: ", formData);
// console.log("Truck List: ", truckListData);
// console.log("Adjustment Form Data: ", adjustmentFormData);
// console.log("Total Adjustment: ", totalAdjustment);
// console.log("all premiums: ", allPremiums);

    const alPremiums = allPremiums[0];
    const glPremiums = allPremiums[1];

    const dataForRequest = await requestData(
      formData,
      truckList,
      driverList,
      adjustmentFormData,
      insuredInfo,
      alPremiums,
      glPremiums
    );

    console.log("Data for request: ", dataForRequest);

    const response = await apiService.printQuote({
      id: policyID,
      userId: auth?.uid,
      payload: dataForRequest,
    });

    if (!isReprint) {
      const docRef = await addDoc(
        collection(db, "policy-applications", policyID, "quotes"),
        {
          adjustmentFormData: {
            ...adjustmentFormData,
            totalAdjustment,
          },
        }
      );

      await updateDoc(doc(db, "policy-applications", policyID), {
        selectedQuote: docRef.id,
      });

      const quoteRef = doc(
        db,
        "policy-applications",
        policyID,
        "quotes",
        docRef.id
      );

      await updateDoc(quoteRef, {
        URL: response.url,
      });

      setAdjustmentFormData({
        percentage: 0,
        debitOrCredit: "Debit",
        recipientFields: [""],
        biLiabilityDeductible: "$1,000",
        renewalCredit: 0,
        lossRatioAdjustmentPercentage: 0,
        safetyAdjustmentPercentage: 0,
      });
    }

    setLoading(false);
    setShowQuoteSuccess(true);
    window.open(response.url, "_blank");
  } catch (error) {
    console.log("Error: " + error.message);
    alert("Error: " + error.message);
    setLoading(false);
  }
};

export default createNewQuote;
