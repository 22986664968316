import calculateAge from "../../../utils/calculateAge";

export const handleDropdown = (
  item,
  type,
  subcollection,
  handleImportFn,
  handleImportAll,
  handleImportInspas,
  t
) => {
  let dropdown = [];
  if (!item) {
    dropdown.push({
      label:
        subcollection === "drivers"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.drivers')}`
          : subcollection === "power-units"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.power-units')}`
          : subcollection === "trailers"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.trailers')}`
          : "",
      onClick: handleImportFn,
      type: "normal-item",
    });
    dropdown.push({
      label: `${t('plm.drivers.details.import')} ${t('plm.drivers.details.all-list')}`,
      onClick: handleImportAll,
      type: "normal-item",
    });
    // dropdown.push({
    //   label: `Import Inspas Data`,
    //   onClick: handleImportInspas,
    //   type: "normal-item",
    // });
  }
  // if (subcollection === "drivers") {
  //   if (!item || item.licenseCountry === "CA") {
  //     dropdown.push({
  //       label: Boolean(item?.caDriverAbstract?.length)
  //         ? `${t('plm.drivers.details.ca-driver-abstract')} ${t('plm.drivers.details.uploaded')}`
  //         : type === "import"
  //         ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.ca-driver-abstract')}`
  //         : `${t('plm.drivers.details.ca-driver-abstract')}`,
  //       onClick: () => {},
  //       name: "caDriverAbstract",
  //       notAccesible: Boolean(item?.caDriverAbstract?.length),
  //       multiple: false,
  //       type: "file",
  //       valueToCompare:
  //         item ? null :
  //         subcollection === "drivers" ?
  //         'licenseNumber' :
  //         subcollection === "power-units" ?
  //         'truckVIN' :
  //         subcollection === "trailers" ?
  //         'trailerVIN' : '',
  //     });
  //   }
  //   dropdown.push({
  //     label: Boolean(item?.driverLicenseFront?.length)
  //       ? `${t('plm.drivers.details.front-license')} ${t('plm.drivers.details.uploaded')}`
  //       : type === "import"
  //       ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.front-license')}`
  //       : `${t('plm.drivers.details.front-license')}`, 
  //     onClick: () => {},
  //     name: "driverLicenseFront",
  //     notAccesible: Boolean(item?.driverLicenseFront?.length),
  //     multiple: false,
  //     type: "file",
  //     valueToCompare:
  //       item ? null :
  //       subcollection === "drivers" ?
  //       'licenseNumber' :
  //       subcollection === "power-units" ?
  //       'truckVIN' :
  //       subcollection === "trailers" ?
  //       'trailerVIN' : '',
  //   });
  //   dropdown.push({
  //     label: Boolean(item?.driverLicenseBack?.length)
  //       ? `${t('plm.drivers.details.back-license')} ${t('plm.drivers.details.uploaded')}`
  //       : type === "import"
  //       ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.back-license')}`
  //       : `${t('plm.drivers.details.back-license')}`,
  //     onClick: () => {},
  //     name: "driverLicenseBack",
  //     notAccesible: Boolean(item?.driverLicenseBack?.length),
  //     multiple: false,
  //     type: "file",
  //     valueToCompare:
  //       item ? null :
  //       subcollection === "drivers" ?
  //       'licenseNumber' :
  //       subcollection === "power-units" ?
  //       'truckVIN' :
  //       subcollection === "trailers" ?
  //       'trailerVIN' : '',
  //   });
  //   if (!item || item.licenseCountry === "USA") {
  //     dropdown.push({
  //       label: Boolean(item?.MVR?.length)
  //         ? `${t('plm.drivers.details.mvr')} ${t('plm.drivers.details.uploaded')}`
  //         : type === "import"
  //         ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.mvr')}`
  //         : `${t('plm.drivers.details.mvr')}`,
  //       onClick: () => {},
  //       name: "MVR",
  //       notAccesible: Boolean(item?.MVR?.length),
  //       multiple: false,
  //       type: "file",
  //       valueToCompare:
  //         item ? null :
  //         subcollection === "drivers" ?
  //         'licenseNumber' :
  //         subcollection === "power-units" ?
  //         'truckVIN' :
  //         subcollection === "trailers" ?
  //         'trailerVIN' : '',
  //     });
  //   }
  //   if (!item || calculateAge(item.dob) > 64) {
  //     dropdown.push({
  //       label: Boolean(item?.medicalLongForm?.length)
  //         ? `${t('plm.drivers.details.medical-long-form')} ${t('plm.drivers.details.uploaded')}`
  //         : type === "import"
  //         ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.medical-long-form')}`
  //         : `${t('plm.drivers.details.medical-long-form')}`,
  //       onClick: () => {},
  //       name: "medicalLongForm",
  //       notAccesible: Boolean(item?.medicalLongForm?.length),
  //       multiple: false,
  //       type: "file",
  //       valueToCompare:
  //         item ? null :
  //         subcollection === "drivers" ?
  //         'licenseNumber' :
  //         subcollection === "power-units" ?
  //         'truckVIN' :
  //         subcollection === "trailers" ?
  //         'trailerVIN' : '',
  //     });
  //   }
  //   if (!item || item.tutor === "additionalDocument") {
  //     dropdown.push({
  //       label: Boolean(item?.additionalDocument?.length)
  //         ? `${t('plm.drivers.details.driver-additional-documents')} ${t('plm.drivers.details.uploaded')}`
  //         : type === "import"
  //         ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.driver-additional-documents')}`
  //         : `${t('plm.drivers.details.driver-additional-documents')}`,
  //       onClick: () => {},
  //       name: "additionalDocument",
  //       notAccesible: Boolean(item?.additionalDocument?.length),
  //       multiple: true,
  //       type: "file",
  //       valueToCompare:
  //         item ? null :
  //         subcollection === "drivers" ?
  //         'licenseNumber' :
  //         subcollection === "power-units" ?
  //         'truckVIN' :
  //         subcollection === "trailers" ?
  //         'trailerVIN' : '',
  //     });
  //   }
  //   dropdown.push({
  //     label: Boolean(item?.otherDocuments?.length)
  //       ? `${t('plm.drivers.details.other-documents')} ${t('plm.drivers.details.uploaded')}`
  //       : type === "import"
  //       ? `${t('plm.drivers.details.bulk')} ${t('plm.drivers.details.other-documents')}`
  //       : `${t('plm.drivers.details.other-documents')}`,
  //     onClick: () => {},
  //     name: "otherDocuments",
  //     notAccesible: Boolean(item?.otherDocuments?.length),
  //     multiple: true,
  //     type: "file",
  //     valueToCompare:
  //       item ? null :
  //       subcollection === "drivers" ?
  //       'licenseNumber' :
  //       subcollection === "power-units" ?
  //       'truckVIN' :
  //       subcollection === "trailers" ?
  //       'trailerVIN' : '',
  //   });
  // }
  return dropdown;
};

export const handleDropdownPowerUnit = (
  item,
  type,
  subcollection,
  handleImportFn,
  handleImportAll,
  handleImportInspas,
  t
) => {
  let dropdown = [];
  if (!item) {
    dropdown.push({
      label:
        subcollection === "drivers"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.drivers')}`
          : subcollection === "power-units"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.power-units')}`
          : subcollection === "trailers"
          ? `${t('plm.drivers.details.import')} ${t('plm.drivers.details.trailers')}`
          : "",
      onClick: handleImportFn,
      type: "normal-item",
    });
    dropdown.push({
      label: `${t('plm.drivers.details.import')} ${t('plm.drivers.details.all-list')}`,
      onClick: handleImportAll,
      type: "normal-item",
    });
    // dropdown.push({
    //   label: `Import Inspas Data`,
    //   onClick: handleImportInspas,
    //   type: "normal-item",
    // });
  }
  // if (subcollection === "power-units" && item) {
  //     dropdown.push({
  //       label: Boolean(item?.truckDocuments?.length)
  //         ? `Upload Truck Documents`
  //         : type === "import"
  //         ? `Bulk Truck Documents`
  //         : `Truck Documents`,
  //       onClick: () => {},
  //       name: "truckDocuments",
  //       notAccesible: Boolean(item?.truckDocuments?.length),
  //       multiple: true,
  //       type: "file",
  //       valueToCompare: null
  //     });
  // }
  return dropdown;
};