import React from "react";
import ActionItemTracker from "./sections/ActionItemTracker"; // Import the ActionItemTracker component
import FinalNotes from "./sections/FinalNotes"; // Import the FinalNotes component
import HiringProcess from "./sections/HiringProcess"; // Import the HiringProcess component
import Training from "./sections/Training"; // Import the Training component
import ClearingHouseRegistration from "./sections/ClearingHouseRegistration"; // Import the ClearingHouseRegistration component
import AlertsOnBASICS from "./sections/AlertsOnBasics"; // Import the AlertsOnBASICS component
import MCS150 from "./sections/MCS150"; // Import the MCS150 component
import VehicleMaintenanceAnalysis from "./sections/VehicleMaintenanceAnalysis"; // Import the VehicleMaintenanceAnalysis component
import HoursOfServiceAnalysis from "./sections/HoursOfServiceAnalysis"; // Import the HoursOfServiceAnalysis component
import SafetyManual from "./sections/SafetyManual"; // Import the SafetyManual component
import CrashAndAccidents from "./sections/CrashAndAccidents"; // Import the CrashAndAccidents component
import Audits from "./sections/Audits"; // Import the Audits component
import AddedInformationForUnits from "./sections/AddedInformationForUnits"; // Import the AddedInformationForUnits component
import Tools from "./sections/Tools"; // Import the Tools component
import AttendeesAndAgents from "./sections/AttendeesAndAgents";

const LossControlReport = ({ reportId }) => {
  const handleInputChange = (event) => {
    // Handle input change logic here
    console.log(event.target.name, event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted");
  };
  return (
    <div className="my-3">
      <form onSubmit={handleSubmit}>
        <AttendeesAndAgents handleInputChange={handleInputChange} />
        <HiringProcess handleInputChange={handleInputChange} />
        <Training handleInputChange={handleInputChange} />
        <ClearingHouseRegistration handleInputChange={handleInputChange} />
        <AlertsOnBASICS handleInputChange={handleInputChange} />
        <MCS150 handleInputChange={handleInputChange} />
        <VehicleMaintenanceAnalysis handleInputChange={handleInputChange} />
        <HoursOfServiceAnalysis handleInputChange={handleInputChange} />
        <SafetyManual handleInputChange={handleInputChange} />
        <CrashAndAccidents handleInputChange={handleInputChange} />
        <Audits handleInputChange={handleInputChange} />
        <AddedInformationForUnits handleInputChange={handleInputChange} />
        <Tools handleInputChange={handleInputChange} />
        <ActionItemTracker handleInputChange={handleInputChange} />
        <FinalNotes handleInputChange={handleInputChange} />
      </form>
    </div>
  );
};

export default LossControlReport;
