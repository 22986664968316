import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { t } from "i18next";
import Checkbox from "../Quotes/APDandMTC/APD/Checkbox";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import KPBrokersInfo from "../KPBrokersInfo";
import EditDropdownModal from "./EditDropdownModal"; // Import the modal
import { Button } from "@mui/material";
import { numberWithCommas } from "../../../utils/helpers";

export default function APD({
  userInfo,
  policyID,
  formData,
  setFormData,
  isAdminOrUnderwriter,
  loadedPolicies,
  policies,
}) {
  const [dropdownOptions, setDropdownOptions] = useState({
    targetDeductibles: [],
    anyOneLossLimits: [],
    towingStorageAndCleanUpLimits: [],
    nonOwnedHiredAutoLimits: [],
    trailerInterchangeLimits: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Auto Physical Damage" },
  ];

  const disabledRule =
    formData.documentType !== "Quote Request" &&
    formData.documentType !== "Application";

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", "apdDropdowns"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(doc.data());
          console.log(doc.data());
        } else {
          console.log("No dropdown options found.");
        }
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDropdownChange = async (field, value) => {
    const apdDetails = {
      ...(formData.apdDetails || {}),
      [field]: value,
    };

    setFormData({
      ...formData,
      apdDetails,
    });

    const policyRef = doc(db, "policy-applications", policyID);
    await updateDoc(policyRef, {
      apdDetails,
    });
  };

  const handleEditClick = (field) => {
    setSelectedField(field);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedField(null);
  };

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-3 md:mb-0">
          Auto Physical Damage
        </span>
      </div>

      <KPBrokersInfo
        activePolicies={policies.activeAPDPolicies}
        userInfo={userInfo}
      />

      {loadedPolicies && !(policies.activeAPDPolicies.length > 0) && (
        <>
          {!formData?.hasAPDCoverage ? (
            <p className="mb-3">
              Add APD to the {formData.documentType} to get started.
            </p>
          ) : (
            <fieldset>
              <h1 className="text-lg mt-3">Deductible</h1>
              <label
                htmlFor={`select-target-deductible`}
                className="block text-sm font-medium text-gray-700"
              >
                Target Deductible
              </label>
              <select
                disabled={disabledRule}
                id={`select-target-deductible`}
                onChange={(e) =>
                  handleDropdownChange("targetDeductible", e.target.value)
                }
                value={formData?.apdDetails?.targetDeductible || ""}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              >
                <option value="">Choose a Deductible</option>
                {dropdownOptions.targetDeductibles
                  .slice()
                  .sort((a, b) => a - b)
                  .map((deductible) => (
                    <option key={deductible} value={deductible}>
                      ${numberWithCommas(deductible)}
                    </option>
                  ))}
              </select>
              {isAdminOrUnderwriter && (
                <Button
                  sx={{ fontSize: "10px" }}
                  onClick={() => handleEditClick("targetDeductibles")}
                >
                  Edit Options
                </Button>
              )}

              <h1 className="text-lg mt-3">Limits</h1>

              <label
                htmlFor={`anyOneLossLimit-select`}
                className="block text-sm font-medium text-gray-700"
              >
                Target Any One Loss Limit
              </label>
              <select
                disabled={disabledRule}
                id={`anyOneLossLimit-select`}
                onChange={(e) =>
                  handleDropdownChange("anyOneLossLimit", e.target.value)
                }
                value={formData?.apdDetails?.anyOneLossLimit || ""}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
              >
                <option value="">Choose A Loss Limit</option>
                {dropdownOptions.anyOneLossLimits
                  .slice()
                  .sort((a, b) => a - b)
                  .map((limit) => (
                    <option key={limit} value={limit}>
                      ${numberWithCommas(limit)}
                    </option>
                  ))}
              </select>
              {isAdminOrUnderwriter && (
                <Button
                  sx={{ fontSize: "10px" }}
                  onClick={() => handleEditClick("anyOneLossLimits")}
                >
                  Edit Options
                </Button>
              )}

              <h1 className="text-lg mt-3">Non Standard Clauses</h1>
              <Checkbox
                disabledRule={disabledRule}
                label={t("Towing, Storage and Clean-up")}
                field={"towingStorageAndCleanUp"}
                policyID={policyID}
                setFormData={setFormData}
                formData={formData}
                defaultToTrue={true}
              />
              {formData?.apdDetails?.towingStorageAndCleanUp && (
                <div className="mb-3">
                  <label
                    htmlFor={`towingStorageAndCleanUp-select`}
                    className="ml-2"
                  >
                    Select a target limit:
                  </label>
                  <select
                    disabled={disabledRule}
                    id={`towingStorageAndCleanUp-select`}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
                    value={
                      formData?.apdDetails?.towingStorageAndCleanUpLimit || ""
                    }
                    onChange={(e) =>
                      handleDropdownChange(
                        "towingStorageAndCleanUpLimit",
                        e.target.value
                      )
                    }
                  >
                    <option value="">Choose One</option>
                    {dropdownOptions.towingStorageAndCleanUpLimits
                      .slice()
                      .sort((a, b) => a - b)
                      .map((limit) => (
                        <option key={limit} value={limit}>
                          ${numberWithCommas(limit)}
                        </option>
                      ))}
                  </select>
                  {isAdminOrUnderwriter && (
                    <Button
                      sx={{ fontSize: "10px" }}
                      onClick={() =>
                        handleEditClick("towingStorageAndCleanUpLimits")
                      }
                    >
                      Edit Options
                    </Button>
                  )}
                </div>
              )}
              <div className="mt-3">
                <Checkbox
                  disabledRule={disabledRule}
                  label={t("Non-Owned Hired Auto Endorsement")}
                  field={"nonOwnedHiredAuto"}
                  policyID={policyID}
                  setFormData={setFormData}
                  formData={formData}
                  defaultToTrue={true}
                />
                {formData?.apdDetails?.nonOwnedHiredAuto && (
                  <div className="mb-3">
                    <label
                      htmlFor={`nonOwnedHiredAuto-select`}
                      className="ml-2"
                    >
                      Select a target limit:
                    </label>
                    <select
                      disabled={disabledRule}
                      id={`nonOwnedHiredAuto-select`}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
                      value={formData?.apdDetails?.nonOwnedHiredAutoLimit || ""}
                      onChange={(e) =>
                        handleDropdownChange(
                          "nonOwnedHiredAutoLimit",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Choose One</option>
                      {dropdownOptions.nonOwnedHiredAutoLimits
                        .slice()
                        .sort((a, b) => a - b)
                        .map((limit) => (
                          <option key={limit} value={limit}>
                            ${numberWithCommas(limit)}
                          </option>
                        ))}
                    </select>
                    {isAdminOrUnderwriter && (
                      <Button
                        sx={{ fontSize: "10px" }}
                        onClick={() =>
                          handleEditClick("nonOwnedHiredAutoLimits")
                        }
                      >
                        Edit Options
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <Checkbox
                  disabledRule={disabledRule}
                  label={t("Attached Trailer Interchange")}
                  field={"trailerInterchangeTarget"}
                  policyID={policyID}
                  setFormData={setFormData}
                  formData={formData}
                  defaultToTrue={true}
                />
                {formData?.apdDetails?.trailerInterchangeTarget && (
                  <div className="mb-3">
                    <label
                      htmlFor={`trailerInterchangeTarget-select`}
                      className="ml-2"
                    >
                      Select a target limit:
                    </label>
                    <select
                      disabled={disabledRule}
                      id={`trailerInterchangeTarget-select`}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300 sm:text-sm"
                      value={
                        formData?.apdDetails?.trailerInterchangeLimit || ""
                      }
                      onChange={(e) =>
                        handleDropdownChange(
                          "trailerInterchangeLimit",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Choose One</option>
                      {dropdownOptions.trailerInterchangeLimits
                        .slice()
                        .sort((a, b) => a - b)
                        .map((limit) => (
                          <option key={limit} value={limit}>
                            ${numberWithCommas(limit)}
                          </option>
                        ))}
                    </select>
                    {isAdminOrUnderwriter && (
                      <Button
                        sx={{ fontSize: "10px" }}
                        onClick={() =>
                          handleEditClick("trailerInterchangeLimits")
                        }
                      >
                        Edit Options
                      </Button>
                    )}

                    <label
                      htmlFor={`trailerInterchangeTarget-select`}
                      className="d-block mt-3 ml-2"
                    >
                      Briefly Describe Exposure:
                    </label>
                    <input
                      id={`tiExposure`}
                      type="text"
                      placeholder={t("Example: 15 trailers full time")}
                      disabled={disabledRule}
                      value={formData?.apdDetails?.tiExposure || ""}
                      onChange={(e) =>
                        handleDropdownChange("tiExposure", e.target.value)
                      }
                      className="mt-1 w-full rounded p-2 border border-gray-300 focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>
                )}
              </div>
            </fieldset>
          )}
        </>
      )}

      {/* Include the EditDropdownModal component */}
      <EditDropdownModal
        open={isModalOpen}
        onClose={handleCloseModal}
        field={selectedField}
      />
    </div>
  );
}
