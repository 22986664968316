import numberWithCommas from "../../../utils/numberWithCommas";
import calculateTaxesAPD from "../../../utils/premiumCalculation/calculateTaxesAPD";
import calculateTaxesMTC from "../../../utils/premiumCalculation/calculateTaxesMTC";

function camelCaseToTitleCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b\w/g, (word) => word.toUpperCase());
}

function mapCommoditiesToArray(formData) {
  const resultArray = [];

  if (formData && formData?.standardCommodityPercentages) {
    for (const fieldName in formData?.standardCommodityPercentages) {
      const fieldValue = formData?.standardCommodityPercentages[fieldName];
      if (fieldValue && fieldValue > 0) {
        const titleCaseFieldName = camelCaseToTitleCase(fieldName);
        resultArray.push(titleCaseFieldName);
      }
    }
  }

  if (formData?.commodityFields) {
    formData?.commodityFields.forEach((commodity) => {
      const commodityDescription = commodity?.commodityDescription;
      if (commodityDescription) {
        resultArray.push(commodityDescription);
      }
    });
  }

  return resultArray.join(", ");
}


async function calculateTaxesAndFees(
  policyID,
  formData,
  alPremiums,
  glPremiums,
  mtcPremiums,
  apdPremiums,
  truckList
) {
  // powerUnitCount is the total number of power units in the truckList with a status of "Active"
  const powerUnitCount = truckList.filter(truck => truck.status === "Active").length;

  // Get AL Taxes & Fees
  const {
    otherFees = 0,
    processingFeeAmount = 0,
    stampTaxAmount = 0,
    surplusLinesTaxAmount = 0,
  } = alPremiums;
  // Get GL Taxes & Fees
  const {
    processingFeeAmount: glProcessingFeeAmount = 0,
    stampTaxAmount: glStampTaxAmount = 0,
    surplusLinesTaxAmount: glSurplusLinesTaxAmount = 0,
  } = glPremiums || {};
  // Get APD Taxes
  const {
    stampTaxAmount: apdStampTaxAmount = 0,
    surplusLinesTaxAmount: apdSurplusLinesTaxAmount = 0,
    policyFeeAmount: apdPolicyFeeAmount = 0,
  } = (await calculateTaxesAPD(policyID, formData, apdPremiums, powerUnitCount || 0)) || {};
  // Get MTC Taxes
  const {
    stampTaxAmount: mtcStampTaxAmount = 0,
    surplusLinesTaxAmount: mtcSurplusLinesTaxAmount = 0,
    policyFeeAmount: mtcPolicyFeeAmount = 0,
  } = (await calculateTaxesMTC(policyID, formData, mtcPremiums, powerUnitCount || 0)) || {};

  // Calculate the total
  const total =
    otherFees +
    processingFeeAmount +
    stampTaxAmount +
    surplusLinesTaxAmount +
    glProcessingFeeAmount +
    glStampTaxAmount +
    glSurplusLinesTaxAmount +
    apdStampTaxAmount +
    apdSurplusLinesTaxAmount +
    apdPolicyFeeAmount +
    mtcStampTaxAmount +
    mtcSurplusLinesTaxAmount +
    mtcPolicyFeeAmount;

  // Debugging
  console.log("Total before formatting:", total);

  // Ensure total is a number before calling toFixed
  if (typeof total === "number" && !isNaN(total)) {
    return parseInt(total.toFixed(2));
  } else {
    throw new Error("Total is not a valid number.");
  }
}

function findGrandTotal(
  formData,
  taxesAndFees,
  alPremiums,
  glPremiums,
  mtcPremium,
  apdPremium
) {
  const total =
    parseFloat(taxesAndFees) +
    parseFloat(formData?.hasALCoverage ? alPremiums?.GWP : 0) +
    parseFloat(formData?.hasGLCoverage ? glPremiums?.GWP : 0) +
    parseFloat(formData?.hasMTCCoverage ? mtcPremium : 0) +
    parseFloat(formData?.hasAPDCoverage ? apdPremium : 0);

  const totalWithCommas = numberWithCommas(total?.toFixed(2));
  return totalWithCommas;
}

function calculateTotalInsuredValue(truckList) {
  return truckList.reduce((acc, truck) => acc + truck.truckACV, 0);
}

const requestData = async (
  formData,
  truckList,
  driverList,
  adjustmentFormData,
  insuredInfo,
  alPremiums,
  glPremiums
) => {
  // console.log("insured info: ", insuredInfo);
  const totalInsuredValue = formData?.TIV;
  const policyID = formData?.id;
  const mtcPremium = formData?.mtcDetails?.totalPremiumBeforeTaxes || 0;
  const apdPremium = formData?.apdDetails?.totalPremiumBeforeTaxes || 0;

  const taxesAndFees = await calculateTaxesAndFees(
    policyID,
    formData,
    alPremiums,
    glPremiums,
    mtcPremium,
    apdPremium,
    truckList
  );

  const grandTotal = findGrandTotal(
    formData,
    taxesAndFees,
    alPremiums,
    glPremiums,
    mtcPremium,
    apdPremium
  );

  const apdCoverage = formData?.hasAPDCoverage
    ? {
        apdCarrier: formData?.carrier,
        apdGeneralAggregateLimit: formData?.apdDetails?.generalAggregateLimit,
        collisionDeductible:
          "$" + numberWithCommas(formData?.apdDetails?.collisionDeductible),
        collisionLimit: "$" + numberWithCommas(totalInsuredValue),
        comprehensiveDeductible:
          "$" + numberWithCommas(formData?.apdDetails?.comprehensiveDeductible),
        comprehensiveLimit: "$" + numberWithCommas(totalInsuredValue),
        apdTrailerInterchangeLimit:
          "$" + numberWithCommas(formData?.apdDetails?.trailerInterchangeLimit),
        apdTowingStorageLimit:
          "$" + numberWithCommas(formData?.apdDetails?.towingAndStorageLimit),
        apdPremium: "$" + numberWithCommas(
          formData?.apdDetails?.totalPremiumBeforeTaxes?.toFixed(2)
        ),
      }
    : {};

  const mtcCoverage = formData?.hasMTCCoverage
    ? {
        mtcCarrier: formData?.mtcCarrier,
        mtcGeneralAggregateLimit:
          "$" + numberWithCommas(formData?.mtcDetails?.generalAggregateLimit),
        cargoDeductible:
          "$" +
          numberWithCommas(formData?.mtcDetails?.generalAggregateDeductible),
        cargoAgLimit:
          "$" + numberWithCommas(formData?.mtcDetails?.generalAggregateLimit),
        reeferDeductible: "",
        //   "$" + numberWithCommas(formData?.mtcDetails?.reeferDeductible),
        reeferLimit: "Included",
        mtcEndorsedCommodities: mapCommoditiesToArray(formData),
        mtcPremium: "$" + numberWithCommas(
          formData?.mtcDetails?.totalPremiumBeforeTaxes?.toFixed(2)
        ),
      }
    : {};

  const result = {
    adjustmentFormData,
    drivers: driverList || [],
    trucks: truckList || [],
    q: {
      // TODO: Add these values to be edited in the admin panel
      galPTL: "$2,000,000",
      productsPTL: "N/A",
      injuryPTL: "N/A",
      occurencePTL: "$1,000,000",
      rentedPTL: "$100,000",
      medExpensePTL: "$5,000",
      createdOn: new Date().toLocaleDateString("en-US"),
      r: "",
      number: "",
      apdCarrier: formData?.apdCarrier,
      mtcCarrier: formData?.mtcCarrier,
      newOrRenewal: formData?.newOrRenewal,
      biLiabilityDeductible:
        "$" + numberWithCommas(formData?.alDetails?.biLiabilityDeductible),
      biLiabilityPTL: "$" + formData?.coverageSingleLimit,
      umLiabilityPTL:
        "$" + numberWithCommas(formData?.alDetails?.uninsuredMotoristLimit),
      hasTI:
        formData?.apdDetails?.trailerInterchange ||
        formData?.mtcDetails?.trailerInterchange,
      personalInjuryProtectionLimit:
        "$" +
        numberWithCommas(formData?.alDetails?.personalInjuryProtectionLimit),
      payInFullAmount: "$" + grandTotal,
      taxesAndFees: "$" + numberWithCommas(taxesAndFees),
      totalInsuredValue,
      hasGLCoverage: formData?.hasGLCoverage ?? false,
      hasALCoverage: formData?.hasALCoverage ?? false,
      hasAPDCoverage: formData?.hasAPDCoverage ?? false,
      hasMTCCoverage: formData?.hasMTCCoverage ?? false,
      hasNonOwned: formData?.hasNonOwned ?? false,
      namedInsured: insuredInfo.company,
      effectiveDate: formData?.effectiveDate
        ?.toDate()
        ?.toLocaleDateString("en-US"),
      expirationDate: new Date(
        new Date(formData?.effectiveDate?.toDate())?.setFullYear(
          new Date(formData?.effectiveDate?.toDate())?.getFullYear() + 1
        )
      ).toLocaleDateString("en-US"),
      numberOfUnits: (truckList || []).filter(
        (truck) =>
          // Filter out trucks that do not have a matching policy in the policyDetailsArray or have a status other than "Active"
          truck.policiesDetails.filter(
            (policiesDetails) =>
              policiesDetails.id === formData?.id &&
              policiesDetails.status === "Active"
          ).length > 0
      ).length,
      alPremium: "$" + numberWithCommas(alPremiums?.GWP?.toFixed(2)) || "ERROR",
      glPremium: formData?.hasGLCoverage
        ? "$" + numberWithCommas(glPremiums?.GWP?.toFixed(2)) || "N/A"
        : "N/A",
      totalPremium: "$" + grandTotal,
      ...apdCoverage,
      ...mtcCoverage,
    },
  };

  console.log("Request Data:", result);
  return result;
};

export default requestData;
