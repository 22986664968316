import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import DynamicTable from "../../../assets/lists/DynamicTable";
import { UNDERWRITING_ENDORSEMENT_ROUTE } from "../../../../utils/routes";
import { useNavigate } from "react-router-dom";
import {
  endorsementDetailsTable,
  endorsementTableColumns,
  filterEndorsementKeysToSearch,
} from "../../../../utils/endorsementDetails";
import { manageEndorsementOptions } from "../../../../utils/searchFilters";
import ListFilters from "../../../../Components/agent/policies/ListFilters";
import useTranslations from "../../../../Context/actions/useTranslations";
import { UserAuth } from "../../../../Context/AuthContent";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import { endorsementPdfPayloads } from "../../../../utils/endorsementsPdfPayloads";
import { db } from "../../../../firebase";
import { doc, getDoc } from "firebase/firestore";

export default function EndoManagerTable({
  endorsements,
  skeletonEndoTableLoader,
  currentEndoFilters,
  setCurrentEndoFilters,
  insuredInfo,
  agencyInfo,
}) {
  const [details, setDetails] = useState([]);
  const { t, language } = useTranslations();
  const { user: auth } = UserAuth();
  const [loading, setLoading] = useState(false);
  const { controlToast } = useGeneralState();

  useEffect(() => {
    endorsementDetailsTable(endorsements, true, language)
      .then((res) => {
        console.log('res', res);
        const { tableData } = res;
        setDetails(tableData);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [endorsements]);

  const navigate = useNavigate();

  const showItem = (e, id) => {
    e.preventDefault();
    const endo = endorsements.find((endo) => endo.id === id);
    navigate(`${UNDERWRITING_ENDORSEMENT_ROUTE}/${id}/${endo?.policyID}`);
  };

  const handleDownload = async (e, item) => {
    e.preventDefault();
    try {
      setLoading(true);
      const endo = endorsements.find((endo) => endo.id === item.id);
      const agencyDocRef = doc(db, "agencies", endo.agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      let agencyInfo = null
      if (agencyDocSnap?.exists()) {
        agencyInfo = agencyDocSnap?.data();
      }
      await endorsementPdfPayloads(endo, auth, insuredInfo, agencyInfo);
      setLoading(false);
      controlToast(true, "Endorsement printing was succesful", "success");
    } catch (error) {
      setLoading(false);
      controlToast(
        true,
        "There was an error printing the endorsement",
        "error"
      );
    }
  };

  return (
    <div>
      <hr className="max-w-[750px] border-1 mt-3 mb-2" />
      <h1 className="text-lg mb-2">{t("endorsements.title-2")}:</h1>
        <div className={loading ? `h-[500px]` : ''}>
          <ListFilters
            options={manageEndorsementOptions}
            currentFilters={currentEndoFilters}
            setCurrentFilters={setCurrentEndoFilters}
          />
          <LoadingScreen
            loading={loading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Printing... Don't leave the screen`}
          >
            <DynamicTable
              skeletonLoader={skeletonEndoTableLoader}
              columns={endorsementTableColumns(true, t)}
              tableData={details}
              deleteThisItem={() => {}}
              onClickItem={showItem}
              filterKeys={filterEndorsementKeysToSearch(true)}
              superEndoSearcher={true}
              handleOpenEditMode={handleDownload}
            />
          </LoadingScreen>
        </div>
    </div>
  );
}
