import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  CircularProgress,
  Alert, // Import Alert for displaying match message
} from "@mui/material";
import {
  onSnapshot,
  collection,
  query,
  setDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import ApprovalButtons from "../ApprovalButtons";
import Popup from "../../../generals/Popup";
import { t } from "i18next";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";

const AssignDocumentToDriverForm = ({
  fileToAssign,
  openAssignDocumentModal,
  setOpenAssignDocumentModal,
  policyFormData,
  selectedDriversName, // Add this prop
}) => {
  const formData = policyFormData;
  const policyID = formData?.id || null;
  const [driverList, setDriverList] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] =
    useState("driverLicenseFront");
  const [loading, setLoading] = useState(true);
  const [matchFound, setMatchFound] = useState(false); // State to manage match status

  const documentTypes = [
    "driverLicenseFront",
    "driverLicenseBack",
    "MVR",
    "medicalLongForm",
  ];

  useEffect(() => {
    setLoading(true); // Set loading state while fetching drivers
    const unsubscribe = getDriverList();
    return () => unsubscribe();
  }, [formData, policyID]);

  useEffect(() => {
    // Set the default driver if there's a match
    if (driverList.length > 0 && selectedDriversName) {
      const matchedDriver = driverList.find(
        (driver) =>
          `${driver.driverFirst} ${driver.driverLast}` === selectedDriversName
      );

      if (matchedDriver) {
        setSelectedDriver(matchedDriver.id);
        setMatchFound(true);
      } else {
        setMatchFound(false);
      }
    }
  }, [driverList, selectedDriversName]);

  const getDriverList = () => {
    console.log(
      "Fetching drivers for agencyID:",
      formData.agencyID,
      "insuredID:",
      formData.insuredID
    );
    console.log("policyID: ", policyID);

    const unsubscribe = onSnapshot(
      query(
        collection(
          db,
          "agencies",
          formData.agencyID,
          "insureds",
          formData.insuredID,
          "drivers"
        )
      ),
      (snapshot) => {
        const drivers = snapshot.docs
          .map((doc) => {
            const policiesDetails = doc.data().policiesDetails;

            if (!Array.isArray(policiesDetails)) {
              console.log(
                "policiesDetails is not an array or is undefined for driver",
                doc.id
              );
              return null;
            }

            const indexOfThisPolicy = policiesDetails.findIndex(
              (policy) => policy.id === policyID
            );

            const driverIsOnPolicy =
              indexOfThisPolicy !== -1 &&
              policiesDetails[indexOfThisPolicy].status === "Active";

            if (driverIsOnPolicy) {
              console.log("Driver is active on this policy:", doc.id);
              return {
                id: doc.id,
                ...doc.data(),
              };
            } else {
              return null;
            }
          })
          .filter((driver) => driver !== null);

        setDriverList(drivers);
        setLoading(false); // Stop loading once drivers are fetched
      },
      (error) => {
        console.error("Error fetching drivers:", error);
        setLoading(false); // Stop loading in case of an error
      }
    );
    return unsubscribe;
  };

  const handleDriverChange = (event) => {
    setSelectedDriver(event.target.value);
  };

  const handleDocumentTypeChange = (event) => {
    setSelectedDocumentType(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData) {
      console.error("Cannot submit, formData is not ready.");
      return;
    }

    console.log("Assigning document to driver:", selectedDriver);

    const driverRef = doc(
      db,
      "agencies",
      formData.agencyID,
      "insureds",
      formData.insuredID,
      "drivers",
      selectedDriver
    );
    const driverDoc = await getDoc(driverRef);
    const driverData = driverDoc.data();
    if (!driverData) {
      console.error("No driver data found for driver:", selectedDriver);
      return;
    }
    await updateDoc(driverRef, { [selectedDocumentType]: fileToAssign });
    console.log("doc updated");
    resetForm();
    setOpenAssignDocumentModal(false);
  };

  const resetForm = () => {
    setSelectedDriver("");
    setSelectedDocumentType("");
    setMatchFound(false); // Reset match status
  };

  const fileName = (file) => {
    if (!file) {
      console.log("No file provided.");
      return "No file";
    }

    // Regex to extract filename and extension
    const regex = /([^/]+)\.([^/.]+)$/;
    const match = file.match(regex);

    if (match) {
      const filename = match[1]; // Extracted filename
      const extension = match[2]; // Extracted extension

      console.log("Filename:", filename);
      console.log("Extension:", extension);
      return filename + "." + extension;
    } else {
      console.log("No valid filename or extension found in the URL.");
      return "Invalid filename";
    }
  };

  return (
    <Popup
      isOpen={openAssignDocumentModal}
      onRequestClose={() => setOpenAssignDocumentModal(false)}
      maxWidth={"500px"}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Assign Document To Driver
        </Typography>
        {/* Include name of file */}
        <Typography variant="body1" gutterBottom>
          {fileToAssign ? fileName(fileToAssign) : "No file to assign"}
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit}>
            {matchFound && (
              <Alert severity="info">Match Found!</Alert> // Display match message
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel id="driver-select-label">Select Driver</InputLabel>
              <Select
                labelId="driver-select-label"
                value={selectedDriver}
                onChange={handleDriverChange}
              >
                {driverList.map((driver) => (
                  <MenuItem key={driver.id} value={driver.id}>
                    {driver.driverFirst + " " + driver.driverLast}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="document-type-select-label">
                Select Document Type
              </InputLabel>
              <Select
                labelId="document-type-select-label"
                value={selectedDocumentType}
                onChange={handleDocumentTypeChange}
              >
                {documentTypes.map((docType) => (
                  <MenuItem key={docType} value={docType}>
                    {camelCaseToTitleCase(docType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="mt-4">
              <ApprovalButtons
                handleAsSubmit={true}
                titleAccept={t("Submit")}
                titleDecline={t("Cancel")}
                onDecline={() => {
                  resetForm();
                  setOpenAssignDocumentModal(false);
                }}
              />
            </div>
          </form>
        )}
      </Box>
    </Popup>
  );
};

export default AssignDocumentToDriverForm;
