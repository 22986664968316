import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Checkbox from "../../assets/forms/Checkbox";
import updatePolicyField from "../../utils/updatePolicyField";
import { useTranslation } from "react-i18next";
import AgentSelection from "./CustomFields/AgentSelection";
import InsuredInfo from "./InsuredInfo";
import TableSkeleton from "../../generals/TableSkeleton";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";
import SelectDropdown from "../../assets/forms/SelectDropdown";
import BlueButton from "../../assets/forms/BlueButton";
import {
  Summarize as SummarizeIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Popup from "../../generals/Popup";
import DynamicTable from "../../assets/lists/DynamicTable";
import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import FormPopup from "../../generals/Popup/FormPopup";
import {
  citiesAZOptions,
  citiesCAOptions,
  citiesNMOptions,
  citiesTXOptions,
} from "../../../utils/constants";
import useGeneralState from "../../../Context/actions/useGeneralState";
import ApiService from "../../../services/southern-api/apiService";
import BindInfo from "./BindInfo";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import useFormDataState from "../../../Context/actions/useFormData";
import {
  calculateExpirationDateTimestamp,
  isLeapYear
} from "../../Finances/Tables/utils";
import Breadcrumbs from "../../assets/Breadcrumbs";

export default function Coverages({
  userInfo,
  formData,
  setFormData,
  policyID,
  insuredInfo,
  language,
  setDisplayCompanyDetails,
  setDisplayBindDetailsInfo,
}) {
  const isAdminOrUnderwriter =
    userInfo.role === "Admin" || userInfo.role === "Underwriter";

  const seePortOfEntry =
    userInfo.role === "Admin" ||
    userInfo.role === "Underwriter" ||
    userInfo.role === "Agent";

  const { t } = useTranslation();
  const { controlToast } = useGeneralState();
  const [showCoverages, setShowCoverages] = useState(false);

  // Check if the screen is mobile
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const hasStateOfEntry =
      formData.stateOfEntry && formData.stateOfEntry !== "";
    const insuredPhysicalState = insuredInfo.state;

    if (insuredInfo.state && !hasStateOfEntry) {
      updatePolicyField(policyID, "stateOfEntry", insuredPhysicalState);
    }
  }, [formData, insuredInfo, policyID]);

  // Update state based on screen size
  useEffect(() => {
    setShowCoverages(!isMobileScreen);
  }, [isMobileScreen]);

  const dropdownOptions = {
    product: [
      { value: "MCA", label: "MCA" },
      { value: "MCE", label: "MCE" },
      { value: "MX", label: "MX" },
    ],
    stateOfEntry: [
      { value: "AZ", label: "Arizona" },
      { value: "CA", label: "California" },
      { value: "NM", label: "New Mexico" },
      { value: "TX", label: "Texas" },
    ],
    coverageSingleLimit: [
      { value: "$1,000,000", label: "$1,000,000" },
      { value: "$750,000", label: "$750,000" },
      { value: "$300,000", label: "$300,000" },
    ],
    program: [
      { value: "NAFTA", label: "NAFTA" },
      { value: "Domestic", label: "Domestic" },
    ],
    newOrRenewal: [
      { value: "New Business", label: t("New Business") },
      { value: "Renewal", label: t("Renewal") },
    ],
    paymentPlan: [
      { value: "External Finance", label: t("External Finance") },
      { value: "Full Payment", label: t("Full Payment") },
    ],
    cargoType: [
      { value: "Non-Hazmat", label: "Non-Hazmat" },
      { value: "Hazmat", label: "Hazmat", disabled: true },
    ],
  };

  const [openFilingsModal, setOpenFilingsModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [proofOfFilingsData, setProofOfFilingsData] = useState([]);
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false);

  const handleOpenFilingsModal = () => {
    setOpenFilingsModal(!openFilingsModal);
  };

  const handleOpenEditMode = (e, body) => {
    e.preventDefault();
    window.open(body.url, "_blank");
  };

  useEffect(() => {
    if (openFilingsModal) {
      const proofOfFilingsRef = collection(db, "proof-of-filings");
      const q = query(
        proofOfFilingsRef,
        where("insuredDOT", "==", insuredInfo?.dot)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const proofOfFilingsResult = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProofOfFilingsData(proofOfFilingsResult);
      });
      return () => unsubscribe();
    }
    return () => {};
  }, [openFilingsModal]);

  const filterKeysForFilings = [
    "creationDate",
    "filingType",
    "submittingUserName",
    "url",
  ];

  const columnsForFilings = [
    {
      name: "creationDate",
      label: "Creation Date",
    },
    {
      name: "filingType",
      label: "Type",
    },
    {
      name: "submittingUserName",
      label: "Submitting User",
    },
    {
      name: "action",
      label: "View",
    },
  ];

  const getAgencyAndInsured = async (agencyID, insuredDOT) => {
    const names = {};
    try {
      const agencyRef = doc(db, "agencies", agencyID);
      const agencySnap = await getDoc(agencyRef);
      if (agencySnap.exists()) {
        names["agencyName"] = agencySnap.data().name;
      }
      const insuredRef = query(
        collectionGroup(db, "insureds"),
        where("dot", "==", insuredDOT)
      );
      const querySnapshot = await getDocs(insuredRef);
      querySnapshot.forEach((doc) => {
        if (doc.data().company) {
          names["insuredName"] = doc.data().company;
        }
      });
      return names;
    } catch (error) {
      console.error(error);
      return {
        agencyName: "",
        insuredName: "",
      };
    }
  };

  const formingTableData = () => {
    const tableData = proofOfFilingsData?.map(async (data, dataIndex) => {
      const {
        creationDate,
        insuredDOT,
        submittingUserName,
        agencyID,
        filingType,
      } = data || {};
      const iconLeft = [];

      const actions = ["view"];

      iconLeft.push({
        name: "redFlag-filings",
        toField: "creationDate",
        className: "text-[#8B0000]",
        onClick: null,
      });

      actions.push("redFlag-filings");

      const iconRight = [
        {
          icon: "delete",
          action: true,
          toField: "action",
        },
      ];

      const { agencyName, insuredName } = await getAgencyAndInsured(
        agencyID,
        insuredDOT
      );

      const parsedCreationDate = creationDate
        ? creationDate.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : null;

      const tableObject = {
        ...data,
        tableInfo: {
          creationDate: {
            text: parsedCreationDate,
          },
          filingType: {
            text: filingType,
          },
          submittingUserName: {
            text: submittingUserName,
          },
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
      tableObject["tableInfo"]["actions"] = {
        actions,
      };
      return tableObject;
    });
    const tableDataSolved = Promise.all(tableData);
    return tableDataSolved;
  };

  useEffect(() => {
    if (proofOfFilingsData.length > 0) {
      formingTableData().then((tableData) => {
        setTableData(tableData);
      });
    }
  }, [formData, openFilingsModal, proofOfFilingsData]);

  const filterOptions = (options) => {
    const cities = options.map((option) => option.key);
    return cities;
  };

  const [openPortOfEntryModal, setOpenPortOfEntryModal] = useState(false);
  const [openPortOfEntryModalLoading, setOpenPortOfEntryModalLoading] =
    useState(false);
  const [portOfEntryCA, setPortOfEntryCA] = useState(
    filterOptions(citiesCAOptions)
  );
  const [portOfEntryAZ, setPortOfEntryAZ] = useState(
    filterOptions(citiesAZOptions)
  );
  const [portOfEntryNM, setPortOfEntryNM] = useState(
    filterOptions(citiesNMOptions)
  );
  const [portOfEntryTX, setPortOfEntryTX] = useState(
    filterOptions(citiesTXOptions)
  );

  useEffect(() => {
    const stateOfEntry = formData?.stateOfEntry || "CA";
    const portOfEntryRef = collection(
      db,
      "ports-of-entry",
      stateOfEntry,
      "ports"
    );
    const q = query(portOfEntryRef);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ids = snapshot.docs.map((doc) => doc.id) || [];
      switch (formData?.stateOfEntry) {
        case "CA":
          setPortOfEntryCA([...filterOptions(citiesCAOptions), ...ids]);
          break;
        case "AZ":
          setPortOfEntryAZ([...filterOptions(citiesAZOptions), ...ids]);
          break;
        case "NM":
          setPortOfEntryNM([...filterOptions(citiesNMOptions), ...ids]);
          break;
        case "TX":
          setPortOfEntryTX([...filterOptions(citiesTXOptions), ...ids]);
          break;
        default:
          break;
      }
    });
    return () => unsubscribe();
  }, [formData?.stateOfEntry, openPortOfEntryModalLoading]);

  const handlePortOfEntrySubmit = async (e, payload) => {
    if (e) e?.preventDefault();
    setOpenPortOfEntryModalLoading(true);
    try {
      const value = payload["new-port-of-entry"];
      const portOfEntryRef = doc(
        db,
        "ports-of-entry",
        formData?.stateOfEntry,
        "ports",
        value
      );
      await setDoc(portOfEntryRef, {}, { merge: true });
      controlToast(
        true,
        `The port of entry ${value} was successfully created in the state of ${formData?.stateOfEntry}`,
        "success"
      );
      handlePortOfEntryModal();
      setOpenPortOfEntryModalLoading(false);
    } catch (error) {
      setOpenPortOfEntryModalLoading(false);
      console.error(error);
      controlToast(
        true,
        "There was an error submitting the port of entry",
        "error"
      );
    }
  };

  const formSettingsAddDriver = {
    onSubmit: handlePortOfEntrySubmit,
    inputs: [
      {
        label: "New Port of Entry",
        name: "new-port-of-entry",
        required: true,
        type: "text",
      },
    ],
    buttonLabel: "Add port of entry",
  };

  const handlePortOfEntryModal = () => {
    setOpenPortOfEntryModal(!openPortOfEntryModal);
  };

  const handleDate = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const { formDataGL } = useFormDataState()

  const [createGLPolicyOpen, setCreateGLPolicyOpen] = useState(false);
  const [createGLPolicyLoading, setCreateGLPolicyLoading] = useState(false);
  const [coverageModalValue, setCoverageModalValue] = useState({
    hasGLCoverage: null
  });

  const handleOpenModalValue = (e) => {
    const { checked, id } = e.target
    setCoverageModalValue({
      ...coverageModalValue,
      [id]: checked
    });
  }

  useEffect(() => {
    if (
      coverageModalValue.hasGLCoverage === true ||
      coverageModalValue.hasGLCoverage === false
    ) {
      setCreateGLPolicyOpen(true);
    }
  }, [coverageModalValue.hasGLCoverage])

  const handleCloseCreateGLPolicy = () => {
    setCreateGLPolicyOpen(false);
    setCoverageModalValue({
      ...coverageModalValue,
      hasGLCoverage: null
    });
  }

  const glPolicyValue = formDataGL?.coverageRequested
  const glPolicyExists = formDataGL?.documentType

  const handleUpdateGLData = async (value) => {
    const policyRef = doc(db, "gl-policies", formDataGL.id);
    await setDoc(policyRef, value, { merge: true });
  }

  const handleClickDate = (event) => {
    event.stopPropagation();
  };

  const createGLPolicySubmit = async (e, value) => {
    e.preventDefault();
    setCreateGLPolicyLoading(true);
    const effectiveDate = value?.['effective-date']
    const expirationDate = calculateExpirationDateTimestamp(
      effectiveDate,
      isLeapYear(effectiveDate) ? 366 : 365
    )

    try {
      if (!glPolicyExists) {
        const policyData = {
          hasGLCoverage: true,
          coverageRequested: true,
          glPolicy: formData?.glPolicy || "",
          glPolicyNo: formData?.glPolicyNo || "",
          effectiveDate,
          expirationDate,
          createdAt: new Date(),
          authorID: auth.currentUser.uid,
          author: auth.currentUser.displayName,
          documentType: "Application",
          status: "Unsubmitted",
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
          program: formData?.program || '',
        };
        await addDoc(collection(db, "gl-policies"), policyData);
      } else {
        const policyRef = doc(db, "gl-policies", formDataGL.id);
        await setDoc(policyRef, {
          hasGLCoverage: !Boolean(formDataGL.hasGLCoverage),
          coverageRequested: !Boolean(formDataGL.coverageRequested)
        }, { merge: true });
      }
      setCreateGLPolicyLoading(false);
      controlToast(
        true,
        !glPolicyExists ?
        "The policy was created successfully" :
        Boolean(formDataGL?.coverageRequested) ?
        "The policy was deleted successfully" :
        "The policy was updated successfully" ,
        "success"
      );
      handleCloseCreateGLPolicy();
    } catch (error) {
      console.error(error);
      setCreateGLPolicyLoading(false);
      controlToast(true, "There was an error creating the policy", "error");
    }
  }

  const inputs = useMemo(() => {
    const inputObj = [
      {
        label: "Effective Date",
        type: "date",
        name: "effective-date",
      }
    ];
    return inputObj;
  }, []);

  const formSettings = {
    onSubmit: createGLPolicySubmit,
    inputs,
    buttonLabel: "Add New GL Policy"
  };

  const filingsBreadcrumbs = [
    { label: "Filings" },
    { label: "Historical" },
  ];

  return (
    <main>
      {glPolicyExists ?
        <Popup
          maxWidth='50%'
          isOpen={createGLPolicyOpen}
          onRequestClose={handleCloseCreateGLPolicy}
        >
          <h2 className="text-xl font-bold mt-2 mb-4">
            Are you sure you would like
            to {glPolicyValue ? 'Remove' : 'Add'} General Liability coverage?
          </h2>
          <div>
            <BlueButton
              text={`${glPolicyValue ? 'Delete' : 'Add'} GL Policy`}
              onClickFunction={createGLPolicySubmit}
              className="w-[100%]"
              icon='no-icon'
              disabled={createGLPolicyLoading}
            />
          </div>
        </Popup> :
        <FormPopup
          isOpen={createGLPolicyOpen}
          onRequestClose={handleCloseCreateGLPolicy}
          title="Are you sure you would like to Add General Liability coverage?"
          formSettings={formSettings}
          loading={createGLPolicyLoading}
          zIndex={1001}
        />
      }
      <FormPopup
        isOpen={openPortOfEntryModal}
        formSettings={formSettingsAddDriver}
        onRequestClose={handlePortOfEntryModal}
        title="Add New Custom Port of Entry"
      />
      <Popup
        isOpen={openFilingsModal}
        onRequestClose={handleOpenFilingsModal}
        maxWidth="700px"
      >
        <div>
        <Breadcrumbs items={filingsBreadcrumbs} />
          <h1 className="text-3xl mt-2 mb-2  font-semibold">
          {insuredInfo.company}
          </h1>
          <DynamicTable
            skeletonLoader={skeletonTableLoader}
            columns={columnsForFilings}
            tableData={tableData}
            handleOpenEditMode={handleOpenEditMode}
            filterKeys={filterKeysForFilings}
            formData={formData}
            sortDefault={{ key: "creationDate", direction: "desc" }}
          />
        </div>
      </Popup>
      {!insuredInfo.company ? (
        <TableSkeleton rows={20} columns={1} />
      ) : (
        <>
          <div className="card shadow-md">
            <div className="card-header bg-[#072a48]">
              <p className={`text-xl text-white`}>
                {/* TODO: Make sure this works with new translation */}
                {language === "en" && (
                  <>
                    {t(formData.status)} {t(formData.documentType)}
                  </>
                )}
                {language === "es" && (
                  <>
                    {t(formData.documentType)} {t(formData.status)}
                  </>
                )}
              </p>
            </div>
            <section className="card-body">
              <InsuredInfo
                insuredInfo={insuredInfo}
                setDisplayCompanyDetails={setDisplayCompanyDetails}
              />
              {formData.documentType === "Policy" && (
                <BindInfo
                  setDisplayBindDetailsInfo={setDisplayBindDetailsInfo}
                />
              )}
              <fieldset>
                <AgentSelection formData={formData} policyID={policyID} />

                <div className="input-container">
                  <SelectDropdown
                    label={t("State Of Entry")}
                    policyID={policyID}
                    formData={formData}
                    options={dropdownOptions.stateOfEntry}
                    fieldName={"stateOfEntry"}
                    disabled={true}
                    labelTitle={`This value is automatically assigned based on the insured's physical address.`}
                  />
                </div>
                {formData.stateOfEntry === "CA" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("Port of Entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryCA
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "AZ" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("Port of Entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryAZ
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "NM" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("Port of Entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryNM
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {formData.stateOfEntry === "TX" && isAdminOrUnderwriter ? (
                  <div className="input-container mb-3">
                    <SelectDropdown
                      label={t("Port of Entry")}
                      policyID={policyID}
                      formData={formData}
                      options={portOfEntryTX
                        .map((port) => {
                          return { value: port, label: port };
                        })
                        .filter((port) => port.value !== "")}
                      fieldName={"portOfEntry"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                ) : null}
                {Boolean(formData.stateOfEntry) && isAdminOrUnderwriter ? (
                  <div>
                    <Typography
                      component="span"
                      onClick={(e) => {
                        e.preventDefault();
                        if (formData.documentType === "Application") {
                          handlePortOfEntryModal();
                        }
                      }}
                      sx={{
                        color: "#0c4a6e",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        cursor:
                          formData.documentType === "Application"
                            ? "pointer"
                            : "not-allowed",
                        textDecoration:
                          formData.documentType === "Application"
                            ? "underline"
                            : "none",
                        opacity:
                          formData.documentType === "Application" ? 1 : 0.5,
                        pointerEvents:
                          formData.documentType === "Application"
                            ? "auto"
                            : "none",
                        "&:hover": {
                          textDecoration:
                            formData.documentType === "Application"
                              ? "underline"
                              : "none",
                        },
                      }}
                    >
                      <AddLocationIcon sx={{ marginRight: "8px" }} />
                      Add Port Of Entry
                    </Typography>
                  </div>
                ) : null}
              </fieldset>
            </section>
            <div
              onClick={(e) => setShowCoverages(!showCoverages)}
              className="card-header bg-gradient-to-r from-stone-200 to-neutral-300"
            >
              <p
                className={`text-lg text-[#072a48]`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Coverages & Filings
                {/* TODO: add this to other collapsibles */}
                <ExpandCollapseArrow
                  show={showCoverages}
                  setShow={setShowCoverages}
                />
              </p>
            </div>
            {showCoverages && (
              <section className="card-body">
                <fieldset>
                  <div className="mb-2">
                    <Grid container spacing={0}>
                      <Grid item xs={formData.hasALCoverage ? 12 : 6}>
                        <Checkbox
                          label={t("AL")}
                          field={"hasALCoverage"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                        {formData.hasALCoverage && (
                          <div className="input-container">
                            <label className="text-capitalize mb-1 d-block">
                              {t("AL Effective Date")}:
                            </label>
                            <div className="flex">
                            <div
                              style={{ position: 'relative', zIndex: 20, width: '100%' }}
                              onClick={handleClickDate}
                            >
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={
                                  formData?.effectiveDate?.toDate?.() || null
                                }
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    effectiveDate: date,
                                  });
                                  updatePolicyField(
                                    policyID,
                                    "effectiveDate",
                                    date
                                  );
                                }}
                                className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                                disabled={formData.documentType !== "Application"}
                              />
                            </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e?.preventDefault();
                                  if (formData?.effectiveDate) {
                                    const date =
                                      formData?.effectiveDate?.toDate?.();
                                    const dateCopiedToClipboard =
                                      handleDate(date);
                                    navigator.clipboard.writeText(
                                      dateCopiedToClipboard
                                    );
                                    controlToast(
                                      true,
                                      "The effective date was copied to clipboard",
                                      "success"
                                    );
                                  }
                                }}
                                className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                                disabled={false}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-copy d-inline"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={formData.hasGLCoverage ? 12 : 6}>
                        <Checkbox
                          label={t("GL")}
                          field={"hasGLCoverage"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formDataGL}
                          disabled={formData.documentType !== "Application"}
                          onChange={handleOpenModalValue}
                        />
                        {formDataGL.hasGLCoverage && (
                          <div className="input-container">
                            <label className="text-capitalize mb-1 d-block">
                              {t("GL Effective Date")}:
                            </label>
                            <div className="flex">
                            <div
                              style={{ position: 'relative', zIndex: 19, width: '100%' }}
                              onClick={handleClickDate}
                            >
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={
                                  formDataGL?.effectiveDate?.toDate?.() || null
                                }
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    glEffectiveDate: date,
                                  });
                                  updatePolicyField(
                                    policyID,
                                    "glEffectiveDate",
                                    date
                                  );
                                  handleUpdateGLData({
                                    effectiveDate: date
                                  })
                                }}
                                className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                                disabled={
                                  formDataGL.documentType !== "Application"
                                }
                              />
                            </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e?.preventDefault();
                                  if (formData?.effectiveDate) {
                                    const date =
                                      formData?.effectiveDate?.toDate?.();
                                    const dateCopiedToClipboard =
                                      handleDate(date);
                                    navigator.clipboard.writeText(
                                      dateCopiedToClipboard
                                    );
                                    controlToast(
                                      true,
                                      "The effective date was copied to clipboard",
                                      "success"
                                    );
                                  }
                                }}
                                className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                                disabled={false}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-copy d-inline"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={formData.hasAPDCoverage ? 12 : 6}>
                        <Checkbox
                          label={t("APD")}
                          field={"hasAPDCoverage"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                        {formData.hasAPDCoverage && (
                          <div className="input-container">
                            <label className="text-capitalize mb-1 d-block">
                              {t("APD Effective Date")}:
                            </label>
                            <div className="flex">
                            <div
                              style={{ position: 'relative', zIndex: 18, width: '100%' }}
                              onClick={handleClickDate}
                            >
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={
                                  formData?.apdEffectiveDate?.toDate?.() || null
                                }
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    apdEffectiveDate: date,
                                  });
                                  updatePolicyField(
                                    policyID,
                                    "apdEffectiveDate",
                                    date
                                  );
                                }}
                                className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                                disabled={
                                  formData.documentType !== "Application"
                                }
                              />
                            </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e?.preventDefault();
                                  if (formData?.effectiveDate) {
                                    const date =
                                      formData?.effectiveDate?.toDate?.();
                                    const dateCopiedToClipboard =
                                      handleDate(date);
                                    navigator.clipboard.writeText(
                                      dateCopiedToClipboard
                                    );
                                    controlToast(
                                      true,
                                      "The effective date was copied to clipboard",
                                      "success"
                                    );
                                  }
                                }}
                                className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                                disabled={false}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-copy d-inline"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={formData.hasMTCCoverage ? 12 : 6}>
                        <Checkbox
                          label={t("MTC")}
                          field={"hasMTCCoverage"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                        {formData.hasMTCCoverage && (
                          <div className="input-container">
                            <label className="text-capitalize mb-1 d-block">
                              {t("MTC Effective Date")}:
                            </label>
                            <div className="flex">
                            <div
                              style={{ position: 'relative', zIndex: 17, width: '100%' }}
                              onClick={handleClickDate}
                            >
                              <DatePicker
                                wrapperClassName="datePicker"
                                selected={
                                  formData?.mtcEffectiveDate?.toDate?.() || null
                                }
                                onChange={(date) => {
                                  setFormData({
                                    ...formData,
                                    mtcEffectiveDate: date,
                                  });
                                  updatePolicyField(
                                    policyID,
                                    "mtcEffectiveDate",
                                    date
                                  );
                                }}
                                className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
                                disabled={
                                  formData.documentType !== "Application"
                                }
                              />
                            </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e?.preventDefault();
                                  if (formData?.effectiveDate) {
                                    const date =
                                      formData?.effectiveDate?.toDate?.();
                                    const dateCopiedToClipboard =
                                      handleDate(date);
                                    navigator.clipboard.writeText(
                                      dateCopiedToClipboard
                                    );
                                    controlToast(
                                      true,
                                      "The effective date was copied to clipboard",
                                      "success"
                                    );
                                  }
                                }}
                                className="cursor-pointer mt-[-4px] ml-2 text-[#0c4a6e] hover:text-[#072a48]"
                                disabled={false}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-copy d-inline"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Checkbox
                          label={t("NOA")}
                          field={"hasNonOwned"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Checkbox
                          label={t("WComp")}
                          field={"hasWorkersComp"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Checkbox
                          label={t("Con. Cargo")}
                          field={"hasContingentCargo"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("Coverage Single Limit")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.coverageSingleLimit}
                      fieldName={"coverageSingleLimit"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  {isAdminOrUnderwriter && (
                    <SelectDropdown
                      label={t("Product")}
                      fieldName={"product"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.product}
                      disabled={formData.documentType !== "Application"}
                    />
                  )}
                  <div className="my-3">
                    <label className="text-capitalize mt-1 mb-2">
                      {t("Filings")}:
                    </label>
                    <Typography
                      component="span"
                      onClick={(e) => {
                        e.preventDefault();
                        handleOpenFilingsModal();
                      }}
                      sx={{
                        color: "#0c4a6e",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#072a48",
                        },
                      }}
                    >
                      <SummarizeIcon sx={{ marginRight: "8px" }} />
                      View Historical Filings
                    </Typography>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6}>
                        <Checkbox
                          label={t("91X (Federal)")}
                          field={"has91X"}
                          policyID={policyID}
                          setFormData={setFormData}
                          formData={formData}
                          disabled={formData.documentType !== "Application"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {formData.stateOfEntry === "TX" && (
                          <div className="input-container">
                            <Checkbox
                              label={t("Form E (State)")}
                              field={"hasFormE"}
                              policyID={policyID}
                              setFormData={setFormData}
                              formData={formData}
                              disabled={formData.documentType !== "Application"}
                            />
                          </div>
                        )}
                        {formData.stateOfEntry === "CA" && (
                          <div className="input-container">
                            <Checkbox
                              label={t("MCP-65 (State)")}
                              field={"hasMCP65"}
                              policyID={policyID}
                              setFormData={setFormData}
                              formData={formData}
                              disabled={formData.documentType !== "Application"}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("New/Renewal")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.newOrRenewal}
                      fieldName={"newOrRenewal"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("Program")}
                      fieldName={"program"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.program}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("Cargo Type")}
                      fieldName={"cargoType"}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.cargoType}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <div className="input-container">
                    <SelectDropdown
                      label={t("Payment Plan")}
                      policyID={policyID}
                      formData={formData}
                      options={dropdownOptions.paymentPlan}
                      fieldName={"paymentPlan"}
                      disabled={formData.documentType !== "Application"}
                    />
                  </div>
                  <Checkbox
                    label={t("Payment Confirmed")}
                    field={"paymentConfirmed"}
                    policyID={policyID}
                    setFormData={setFormData}
                    formData={formData}
                  />
                </fieldset>
              </section>
            )}
          </div>
        </>
      )}
    </main>
  );
}
