import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { calculateAge2 } from "../Components/utils/calculateAge";
import { db } from "../firebase";
import searchByLicense from "../Components/LossControl/utils/getData/searchByLicense";
import searchByName from "../Components/LossControl/utils/getData/searchByName";
import searchByPolicy from "../Components/LossControl/utils/getData/searchByPolicy";
import performJoins from "../Components/LossControl/utils/getData/performJoins";
import mapEventualities from "../Components/LossControl/utils/getData/mapEventualities";
import mapClassifications from "../Components/LossControl/utils/getData/mapClassifications";
import aggregateDriverInfo from "../Components/LossControl/utils/listeners/aggregateDriverInfo";
import getViolationsQuery from "../Components/LossControl/utils/getData/getViolationsQuery";
import ApiService from "../services/southern-api/apiService";
import qs from "qs";
import assignFrequency from "../Components/LossControl/utils/assignFrequency";
import assignSeverity from "../Components/LossControl/utils/assignSeverity";
import assignCombined from "../Components/LossControl/utils/assignCombined";

const apiService = new ApiService("southern");

export const formatDOB = (dob) => {
  if (!dob) return "-";
  let birthDate;
  if (dob.seconds !== undefined && dob.nanoseconds !== undefined) {
    birthDate = new Date(dob.seconds * 1000);
  } 
  else if (dob instanceof Date) {
    birthDate = dob;
  } 
  else {
    birthDate = new Date(dob);
  }
  if (isNaN(birthDate)) return "-";
  return birthDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const driversPayload = async (driver) => {
  const {
    mxNMP,
    licenseNumber
  } = driver;
  // const licenseNumber = "9396361"
  // const licenseNumber = "CHIH115224"
  // const licenseNumber = "COAH101343"
  // const mxNMP = 96656
  if (!licenseNumber && !mxNMP) return;
  try {
    const driversMap = await searchByLicense(licenseNumber);
    const crashesQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "crashes"),
        where("drvLicNbr", "==", licenseNumber)
      )
    );
    const crashesArray = crashesQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const crashes = {
      crashes:
        typeof crashesArray?.length !== 'undefined' &&
        typeof crashesArray?.length === 'number'
          ? crashesArray.length : 0,
      fatalities: crashesArray?.[0]?.fatalities || 0,
      injuries: crashesArray?.[0]?.injuries || 0,
    }

    const inspectionsQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "inspections"),
        where("LicenseNumber", "==", licenseNumber),
        limit(10)
      )
    );
    const inspectionsArray = inspectionsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const violationsQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "violations"),
        where("LicenseNumber", "==", licenseNumber),
        limit(10)
      )
    );
    const violationsArray = violationsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const eventualitiesMap = await mapEventualities();
    const classificationsMap = await mapClassifications();

    const extraViolationsQuerySnapshot = await getViolationsQuery(
      "license",
      licenseNumber
    );

    const fullyJoinedViolations = await performJoins(
      extraViolationsQuerySnapshot,
      eventualitiesMap,
      classificationsMap,
      false,
      violationsArray
    );

    const aggregatedData = await aggregateDriverInfo(
      fullyJoinedViolations || [],
      driversMap,
      crashesArray,
      () => {},
      inspectionsArray
    );

    const aggregatedDataWithCrashes = aggregatedData.map((driverData) => ({
      ...driverData,
      crashes: crashesArray.filter(
        (crash) => crash.drvLicNbr === driverData.LicenseNumber
      ),
    }));

    let responseData = null
    if (mxNMP) {
      try {
        const results = await apiService.updateSCT({
          driverLicenseNumber: licenseNumber,
          driverSCTNumber: mxNMP,
        });
        responseData = qs.parse(results);
      } catch (error) {
        console.error("Error fetching SCT data:", error);
      }
    }

    let parsedTrainings = [];
    const trainings = [];
    try {
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const q = query(
        trainingsCollectionRef,
        where("driversLicenseNumber.driversLicenseNumber", "==", licenseNumber)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        trainings.push({ ...doc.data(), id: doc.id });
      });
      parsedTrainings = trainings.map((training) => {
        return {
          training: training?.trainingName,
          institution: training?.institution,
          completionDate: training?.completionDate?.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
          trainingDuration: '-',
          description: training?.notes
        }
      });
    } catch (error) {
      console.error("Error fetching trainings:", error);
    }

    let policies = [];
    const policiesArray = [];
    for (const policy of driver?.policies) {
      try {
        const policyRef = doc(db, "policy-applications", policy);
        const policySnapshot = await getDoc(policyRef);
        if (policySnapshot.exists() && policySnapshot?.data()?.alPolicyNo) {
          policiesArray.push({
            id: policySnapshot.id,
            ...policySnapshot.data(),
          });
          const insuredRef = doc(
            db,
            "agencies",
            policySnapshot.data().agencyID,
            "insureds",
            policySnapshot.data().insuredID
          );
          const insuredSnapshot = await getDoc(insuredRef);
          let insuredInfo = null;
          if (insuredSnapshot.exists()) {
            insuredInfo = {
              id: insuredSnapshot.id,
              ...insuredSnapshot.data(),
            };
          }
          policies.push({
            policyNbr: policySnapshot.data()?.alPolicyNo,
            dot: insuredInfo?.dot,
            insuredName: insuredInfo?.company,
          });
        }
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    }

    const fetchMatrixData = async (matrixType) => {
      try {
        const matrixRef = collection(db, "loss-control", "tables", matrixType);
        const snapshot = await getDocs(matrixRef);
        const matrix = [];
        snapshot.forEach((doc) => {
          matrix.push(doc.data());
        });
        return matrix;
      } catch (error) {
        console.error(`Error getting ${matrixType} matrix:`, error);
      }
    };

    let frequency = '-'
    let severity = '-'
    let combined = '-'
    try {
      const frequencyMatrix = await fetchMatrixData("frequencyMatrix");
      const severityMatrix = await fetchMatrixData("severityMatrix");
      const combinedMatrix = await fetchMatrixData("combinedMatrix");
      const parsedDriver = aggregatedDataWithCrashes?.[0]
      if (parsedDriver) {
        frequency = frequencyMatrix && frequencyMatrix?.length !== 0 ? assignFrequency(parsedDriver, true, frequencyMatrix) : '-'
        severity = severityMatrix && severityMatrix?.length !== 0 ? assignSeverity(parsedDriver, true, severityMatrix) : '-'
        combined = combinedMatrix && combinedMatrix?.length !== 0 ? assignCombined(parsedDriver, true, combinedMatrix) : '-'
      }
    } catch (error) {
      console.error("Error fetching matrix data:", error);
    }

    const licenseExpiration =
      driver?.licenseExpiration && driver?.licenseExpiration?.toDate ? driver?.licenseExpiration :
      driver?.licenseExpirationDate && driver?.licenseExpirationDate?.toDate ? driver?.licenseExpirationDate : null;

    const data = {
      payload: {
        profileDefinition: {
          url: driver?.driverLicenseFront ? driver?.driverLicenseFront : '',
          name: `${driver?.driverFirst} ${driver?.driverLast}`,
          dob: `${formatDOB(driver?.dob)} (Age: ${calculateAge2(driver?.dob)})`,
          licenseMX: licenseNumber,
          expiresOn: licenseExpiration
            ?.toDate()
            .toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }),
        },
        score: {
          scorePoints: aggregatedDataWithCrashes?.[0]?.scoreTotal?.toFixed(2) || 0,
          violations:
            parseInt(
              aggregatedDataWithCrashes?.[0]?.majorViolationsCount +
              aggregatedDataWithCrashes?.[0]?.minorViolationsCount
            ) || 0,
          claims: aggregatedDataWithCrashes?.[0]?.claimsCount || 0,
          crashes: aggregatedDataWithCrashes?.[0]?.crashesCount || 0,
          training: parsedTrainings.length,
        },
        riskFactor: {
          frequency,
          severity,
          combined
        },
        basicInformation: {
          licenseClass: driver?.licenseClass
            ? driver?.licenseClass
            : driver?.inspasData?.LicClass
            ? driver?.inspasData?.LicClass
            : "-",
          experience: driver?.yoe,
          curp: driver?.inspasData?.DrvCURP ? driver?.inspasData?.DrvCURP : "-",
          scoreStatus: aggregatedDataWithCrashes?.[0]?.result || "-",
          noMedicinaPreventiva:
            driver?.mxNMP && driver?.licenseCountry === "MX"
              ? driver?.mxNMP
              : "Not Applicable",
        },
        sct: {
          effectiveFrom: responseData?.issued || '-',
          effectiveUntil: responseData?.expires || '-',
          fit: responseData?.driverFit || '-',
          examType: responseData?.examType || '-'
        },
        crashes,
        training: parsedTrainings,
        policy: policies,
        inspections: [],
      },
      driverId: driver.id,
    };
    return data;
  } catch (error) {
    console.error("Error processing driver payload:", error);
    throw error;
  }
};