import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { filterPoliciesByIssuedDate, itemEndoProgram, itemProgram } from "./utils";
import { db, DocumentReference } from "../../../firebase";
import { getCounty } from "../../utils/getCounty";

// POLICIES

export const handlePolicySearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false,
  status
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program &&
    !status
  ) {
    controlToast(true, "Select Load All or filter by date, program or status to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const policiesCollection = collection(db, "policy-applications");
      let policiesQuery = query(
        policiesCollection,
        where("documentType", "==", "Policy"),
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data === "load-all") {
        policiesQuery = query(
          policiesQuery,
          where("IssuedDate", "!=", null)
        );
      } else {
        if (data?.["start-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", "<=", data["end-date"])
          );
        if (program)
          policiesQuery = query(policiesQuery, where("program", "==", program));
        if (status)
          policiesQuery = query(policiesQuery, where("status", "==", status));
      }
      let querySnapshot = await getDocs(policiesQuery);
      const updatedPolicyListPromises = querySnapshot.docs.map(
        async (policyDoc) => {
          const policyData = policyDoc.data();
          const { namedInsured, agencyID, ...rest } = policyData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              policyData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);

            const city = insuredDocSnapshot?.data()?.city
            const state = insuredDocSnapshot?.data()?.state
            let counties = []
            if (
              insuredDocSnapshot.exists()
              && city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state)
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse
                }
              } catch (error) {
                console.error("Error getting county: ", error)
              }
            }

            if (insuredDocSnapshot.exists()) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: policyDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );

      const transformAndDuplicate = async (infoArray) => {
        const glPoliciesCollection = collection(db, "gl-policies");
        const resultPromises = infoArray.map(async (info) => {      
          let glPoliciesQuery = query(
            glPoliciesCollection,
            where("documentType", "==", "Policy"),
            where("agencyID", "==", info?.agencyID),
            where("insuredID", "==", info?.insuredID)
          );

          if (data !== "load-all") {
            if (data?.["start-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", ">=", data["start-date"])
              );
            if (data?.["end-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", "<=", data["end-date"])
              );
            if (info?.status)
              glPoliciesQuery = query(glPoliciesQuery, where("status", "==", info.status));
          }
      
          const glQuerySnapshot = await getDocs(glPoliciesQuery);
      
          const glPolicyData = glQuerySnapshot.docs.map((glPolicyDoc) => ({
            ...glPolicyDoc.data(),
            id: glPolicyDoc?.id,
            insuredInfo: info?.insuredInfo,
            agencyInfo: info?.agencyInfo,
            namedInsured: info?.namedInsured,
            program: info?.program,
            product: info?.product,
            IssuedDate: info?.IssuedDate,
            stateOfEntry: info?.stateOfEntry,
            coverageSingleLimit: info?.coverageSingleLimit,
            takeGL: true,
            takeAL: false,
          }));
      
          if (glPolicyData.length > 0) {
            return [
              ...glPolicyData,
              { ...info, takeGL: false, takeAL: true },
            ];
          } else {
            return [{ ...info, takeAL: true, takeGL: false }];
          }
        });
        const resolvedResults = await Promise.all(resultPromises);
        return resolvedResults.flat();
      };
      const updatedPolicyList = await Promise.all(updatedPolicyListPromises);
      const filteredList = updatedPolicyList.filter(Boolean);
      const duplicatedPolicies = await transformAndDuplicate(filteredList);
      const issuedDatePoliciesFiltered = filterPoliciesByIssuedDate(
        duplicatedPolicies,
        data["start-date"],
        data["end-date"]
      );
      if (!isBordereaux) {
        setItemList(issuedDatePoliciesFiltered);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return issuedDatePoliciesFiltered;
    } catch (error) {
      console.error("Error fetching policies: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

// ENDORSEMENTS

export const handleEndorsementSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program
  ) {
    controlToast(true, "Select Load All or a date to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const endoCollection = collectionGroup(db, "endorsements");
      let endoQuery = query(
        endoCollection,
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data === "load-all") {
        endoQuery = query(
          endoQuery,
          where("IssuedDate", "!=", null)
        );
      } else {
        if (data?.["start-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", "<=", data["end-date"])
          );
      }
      const querySnapshot = await getDocs(endoQuery);
      const updatedEndoListPromises = querySnapshot.docs.map(
        async (endoDoc) => {
          const endoData = {
            ...endoDoc.data(),
            id: endoDoc.id,
          };
          const { namedInsured, agencyID, ...rest } = endoData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              endoData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);

            const city = insuredDocSnapshot?.data()?.city;
            const state = insuredDocSnapshot?.data()?.state;
            let counties = [];
            if (
              insuredDocSnapshot.exists() &&
              city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state);
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse;
                }
              } catch (error) {
                console.error("Error getting county: ", error);
              }
            }

            if (
              insuredDocSnapshot.exists() &&
              (endoData.type !== "Add Additional Insureds" ||
                (endoData.type === "Add Additional Insureds" &&
                  endoData?.alPremiums?.GWP > 0)) &&
              (endoData.status === "Issued" ||
              endoData.status === "Approved") &&
              (
                endoData.type === "Add Power Units" ||
                endoData.type === "Remove Power Units" ||
                endoData.type === "Add Trailers" ||
                endoData.type === "Remove Trailers" ||
                endoData.type === "Swap Power Units"
              )
            ) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: endoDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );

      const transformAndDuplicate = async (info) => {
        const results = [];
        for (const item of info) {
          const policyRef = doc(db, "policy-applications", item.policyID);
          const policyDocDocSnapshot = await getDoc(policyRef);
          const policyData = [{
            ...policyDocDocSnapshot?.data(),
            id: policyDocDocSnapshot?.id,
          }];

          let data = item["data"];
          if (Array.isArray(data)) {
            data = await Promise.all(data.map(async (element) => {
              if (element instanceof DocumentReference) {
                const docSnapshot = await getDoc(element);
                return {
                  ...docSnapshot.data(),
                  id: docSnapshot.id,
                };
              } else if (typeof element === 'string') {
                let subcollection = '';
                if (item?.type?.includes('Power Unit')) {
                  subcollection = 'power-units';
                } else if (item?.type?.includes('Trailer')) {
                  subcollection = 'trailers';
                }
                const docSnapshot = await getDoc(
                  doc(db, "agencies", item.agencyID, "insureds", item.insuredID, subcollection, element)
                );
                return {
                  ...docSnapshot.data(),
                  id: docSnapshot.id,
                };
              } 
              else {
                return element;
              }
            }));
          }

          if (
            Array.isArray(data?.newVehicles) &&
            data?.newVehicles.length > 0 &&
            Array.isArray(data?.oldVehicles) &&
            data?.oldVehicles.length > 0
          ) {
            const newVehicleItems = data.newVehicles.map((newVehicle) => ({
              ...item,
              policiesDetails: policyData,
              type: "Swap Power Unit Added",
              data: newVehicle,
            }));
            const oldVehicleItems = data.oldVehicles.map((oldVehicle) => ({
              ...item,
              policiesDetails: policyData,
              type: "Swap Power Unit Removed",
              data: oldVehicle,
            }));
            results.push(...newVehicleItems, ...oldVehicleItems);
          } else if (
            Array.isArray(data) &&
            data.length > 0 &&
            typeof data[0] !== 'string'
          ) {
            data.forEach((dataItem) => {
              results.push({
                ...item,
                data: dataItem,
                policiesDetails: policyData,
              });
            });
          } else if (data && typeof data === "object" && !Array.isArray(data)) {
            results.push({ ...item, policiesDetails: policyData });
          }
        }
        return results;
      };
      const updatedEndoList = await Promise.all(updatedEndoListPromises);
      const duplicatedEndos = await transformAndDuplicate(
        updatedEndoList.filter(Boolean)
      );
      let endoSolvedPromises = duplicatedEndos;
      if (program) {
        endoSolvedPromises = endoSolvedPromises.filter((endo) => {
          const programValue = itemEndoProgram(endo);
          return programValue === program;
        });
      }
      endoSolvedPromises = endoSolvedPromises.filter((endo) => {
        return Boolean(!Array.isArray(endo));
      });
      const issuedDateEndosFiltered = filterPoliciesByIssuedDate(
        endoSolvedPromises,
        data["start-date"],
        data["end-date"]
      );
      if (!isBordereaux) {
        setItemList(issuedDateEndosFiltered);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return issuedDateEndosFiltered;
    } catch (error) {
      console.error("Error fetching endorsements: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

// VIN BORDEREAUX

export const handlePolicyVinSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program
  ) {
    controlToast(true, "Select Load All or a date to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const policiesCollection = collection(db, "policy-applications");
      let policiesQuery = query(
        policiesCollection,
        where("documentType", "==", "Policy"),
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data === "load-all") {
        policiesQuery = query(
          policiesQuery,
          where("IssuedDate", "!=", null)
        );
      } else {
        if (data?.["start-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", "<=", data["end-date"])
          );
        if (program)
          policiesQuery = query(policiesQuery, where("program", "==", program));
      }
      const querySnapshot = await getDocs(policiesQuery);
      const updatedPolicyListPromises = querySnapshot.docs.map(
        async (policyDoc) => {
          const policyData = policyDoc.data();
          const { namedInsured, agencyID, ...rest } = policyData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              policyData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);

            const city = insuredDocSnapshot?.data()?.city
            const state = insuredDocSnapshot?.data()?.state
            let counties = []
            if (
              insuredDocSnapshot.exists()
              && city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state)
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse
                }
              } catch (error) {
                console.error("Error getting county: ", error)
              }
            }

            if (insuredDocSnapshot.exists()) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: policyDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );

      const transformAndDuplicate = async (infoArray) => {
        const glPoliciesCollection = collection(db, "gl-policies");
        const resultPromises = infoArray.map(async (info) => {      
          let glPoliciesQuery = query(
            glPoliciesCollection,
            where("documentType", "==", "Policy"),
            where("agencyID", "==", info?.agencyID),
            where("insuredID", "==", info?.insuredID)
          );

          if (data !== "load-all") {
            if (data?.["start-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", ">=", data["start-date"])
              );
            if (data?.["end-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", "<=", data["end-date"])
              );
            if (info?.status)
              glPoliciesQuery = query(glPoliciesQuery, where("status", "==", info.status));
          }
      
          const glQuerySnapshot = await getDocs(glPoliciesQuery);
      
          const glPolicyData = glQuerySnapshot.docs.map((glPolicyDoc) => ({
            ...glPolicyDoc.data(),
            id: glPolicyDoc?.id,
            insuredInfo: info?.insuredInfo,
            agencyInfo: info?.agencyInfo,
            namedInsured: info?.namedInsured,
            program: info?.program,
            product: info?.product,
            IssuedDate: info?.IssuedDate,
            stateOfEntry: info?.stateOfEntry,
            coverageSingleLimit: info?.coverageSingleLimit,
            takeGL: true,
            takeAL: false,
          }));
      
          if (glPolicyData.length > 0) {
            return [
              ...glPolicyData,
              { ...info, takeGL: false, takeAL: true },
            ];
          } else {
            return [{ ...info, takeAL: true, takeGL: false }];
          }
        });
        const resolvedResults = await Promise.all(resultPromises);
        return resolvedResults.flat();
      };

      const transformAndDuplicate2 = async (info) => {
        const results = [];
        for (const item of info) {
          let policyRef = null;
          let policyData = null;
          if (item.policyID && item?.takeAL === true) {
            policyRef = doc(db, "policy-applications", item.policyID);
            const policyDocDocSnapshot = await getDoc(policyRef);
            policyData = [{
              ...policyDocDocSnapshot?.data(),
              id: policyDocDocSnapshot?.id,
            }];
          }
          const powerUnits = [];
          const powerUnitCollection = collection(
            db,
            "agencies",
            item.agencyID,
            "insureds",
            item.insuredID,
            "power-units"
          );
          const powerUnitQuery = await getDocs(powerUnitCollection);
          powerUnitQuery.forEach((doc) => {
            powerUnits.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          const trailers = [];
          const trailerCollection = collection(
            db,
            "agencies",
            item.agencyID,
            "insureds",
            item.insuredID,
            "trailers"
          );
          const trailerQuery = await getDocs(trailerCollection);
          trailerQuery.forEach((doc) => {
            trailers.push({
              ...doc.data(),
              id: doc.id,
            });
          });
          const allUnits = [...powerUnits, ...trailers];
          if (item?.takeAL === true) {
            allUnits.forEach((unit) => {
              results.push({
                ...item,
                data: {
                  ...unit,
                  individualGWP: `${item?.alPremiums?.GWP / allUnits.length}`,
                },
                policyData: policyData || []
              });
            });
          } else if (item?.takeGL === true) {
            results.push({
              ...item,
              data: {
                individualGWP: item?.glPremiums?.GWP,
              }
            });
          }
        }
        return results;
      };

      const updatedPolicyList = await Promise.all(updatedPolicyListPromises);
      const duplicatedPolicies1 = await transformAndDuplicate(updatedPolicyList.filter(Boolean));
      const duplicatedPolicies2 = await transformAndDuplicate2(duplicatedPolicies1)
      const duplicatedPolicies3 = [
        ...duplicatedPolicies1,
        ...duplicatedPolicies2
      ].flat()
      const issuedDateEndosFiltered = filterPoliciesByIssuedDate(
        duplicatedPolicies3,
        data["start-date"],
        data["end-date"]
      );
      if (!isBordereaux) {
        setItemList(issuedDateEndosFiltered);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return issuedDateEndosFiltered;
    } catch (error) {
      console.error("Error fetching policies: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

export const handleEndorsementVinSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program
  ) {
    controlToast(true, "Select Load All or a date to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const endoCollection = collectionGroup(db, "endorsements");
      let endoQuery = query(
        endoCollection,
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data !== "load-all") {
        if (data?.["start-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", "<=", data["end-date"])
          );
      }
      const querySnapshot = await getDocs(endoQuery);
      const updatedEndoListPromises = querySnapshot.docs.map(
        async (endoDoc) => {
          const endoData = endoDoc.data();
          const { namedInsured, agencyID, ...rest } = endoData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              endoData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);
            
            const city = insuredDocSnapshot?.data()?.city
            const state = insuredDocSnapshot?.data()?.state
            let counties = []
            if (
              insuredDocSnapshot.exists()
              && city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state)
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse
                }
              } catch (error) {
                console.error("Error getting county: ", error)
              }
            }

            if (
              insuredDocSnapshot.exists() &&
              (endoData.type !== "Add Additional Insureds" ||
                (endoData.type === "Add Additional Insureds" &&
                  endoData?.alPremiums?.GWP > 0)) &&
                (endoData.status === "Issued" ||
                endoData.status === "Approved") &&
                (
                  endoData.type === "Add Power Units" ||
                  endoData.type === "Remove Power Units" ||
                  endoData.type === "Add Trailers" ||
                  endoData.type === "Remove Trailers" ||
                  endoData.type === "Swap Power Units"
                )
            ) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: endoDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );

      const transformAndDuplicate = async (info) => {
        const results = [];
        for (const item of info) {
          let policyRef = null
          let policyData = null
          if (item.policyID) {
            policyRef = doc(db, "policy-applications", item.policyID);
            const policyDocDocSnapshot = await getDoc(policyRef);
            policyData = [{
              ...policyDocDocSnapshot?.data(),
              id: policyDocDocSnapshot?.id,
            }];
          }
          let data = item["data"];
          if (Array.isArray(data)) {
            data = await Promise.all(data.map(async (element) => {
              if (element instanceof DocumentReference) {
                const docSnapshot = await getDoc(element);
                return {
                  ...docSnapshot.data(),
                  id: docSnapshot.id,
                };
              } else if (typeof element === 'string') {
                let subcollection = '';
                if (item?.type?.includes('Power Unit')) {
                  subcollection = 'power-units';
                } else if (item?.type?.includes('Trailer')) {
                  subcollection = 'trailers';
                }
                const docSnapshot = await getDoc(
                  doc(db, "agencies", item.agencyID, "insureds", item.insuredID, subcollection, element)
                );
                return {
                  ...docSnapshot.data(),
                  id: docSnapshot.id,
                };
              } 
              else {
                return element;
              }
            }));
          }
          if (
            Array.isArray(data?.newVehicles) &&
            data?.newVehicles.length > 0 &&
            Array.isArray(data?.oldVehicles) &&
            data?.oldVehicles.length > 0
          ) {
            const newVehicleItems = data.newVehicles.map((newVehicle) => ({
              ...item,
              policiesDetails: policyData,
              type: "Swap Power Unit Added",
              data: newVehicle,
            }));
            const oldVehicleItems = data.oldVehicles.map((oldVehicle) => ({
              ...item,
              policiesDetails: policyData,
              type: "Swap Power Unit Removed",
              data: oldVehicle,
            }));
            results.push(...newVehicleItems, ...oldVehicleItems);
          } else if (
            Array.isArray(data) &&
            data.length > 0 &&
            typeof data[0] !== 'string'
          ) {
            data.forEach((dataItem) => {
              results.push({
                ...item,
                data: dataItem,
                policiesDetails: policyData,
              });
            });
          } else if (data && typeof data === "object" && !Array.isArray(data)) {
            results.push({ ...item, policiesDetails: policyData });
          }
        }
        return results;
      };

      const transformAndDuplicate1 = (info) => {
        return info.flatMap(async (item) => {
          const policyRef = doc(db, "policy-applications", item.policyID);
          let policyData = null;
          const policyDocDocSnapshot = await getDoc(policyRef);
          policyData = policyDocDocSnapshot?.data();
          const data = item["data"];
          if (data && policyData) {
            return {
              ...item,
              policyData
            };
          } else {
            return {...item}
          }
        });
      };
      const flattenArray = (array) => {
        return array.flatMap(item => {
          if (Array.isArray(item)) {
            return flattenArray(item);
          } else if (typeof item === 'object' && item !== null) { 
            return [item];
          }
          return [];
        });
      }
      const updatedEndoList = await Promise.all(updatedEndoListPromises);
      const duplicatedEndos1 = await transformAndDuplicate(updatedEndoList.filter(Boolean))
      const duplicatedEndos2 = flattenArray(duplicatedEndos1)
      const duplicatedEndos3 = await Promise.all(transformAndDuplicate1(duplicatedEndos2))
      let endoSolvedPromises = await Promise.all(duplicatedEndos3);
      if (program) {
        endoSolvedPromises = endoSolvedPromises.filter((endo) => {
          const programValue = itemEndoProgram(endo);
          return programValue === program;
        });
      }
      endoSolvedPromises = endoSolvedPromises.filter((endo) => {
        return Boolean(!Array.isArray(endo))
      });
      if (!isBordereaux) {
        setItemList(endoSolvedPromises);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return endoSolvedPromises;
    } catch (error) {
      console.error("Error fetching endorsements: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

export const handleBordereauxVinSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  bordereauxType
) => {
  try {
    const policies = await handlePolicyVinSearch(
      data,
      program,
      controlToast,
      setLoading,
      setItemList,
      setStartDateUsed,
      setEndDateUsed,
      setDidRequest,
      userInfo,
      type,
      "bordereaux",
      bordereauxType
    );
    if (policies) {
      const endos = await handleEndorsementVinSearch(
        data,
        program,
        controlToast,
        setLoading,
        setItemList,
        setStartDateUsed,
        setEndDateUsed,
        setDidRequest,
        userInfo,
        type,
        "bordereaux",
        bordereauxType
      );
      setItemList([...policies, ...endos]);
      setStartDateUsed(data["start-date"]);
      setEndDateUsed(data["end-date"]);
    }
  } catch (error) {
    console.error("Error fetching bordereaux: ", error);
  } finally {
    setLoading(false);
    setDidRequest(true);
  }
};

// MAIN BORDEREAUX

export const handlePolicyMainSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program
  ) {
    controlToast(true, "Select Load All or a date to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const policiesCollection = collection(db, "policy-applications");
      let policiesQuery = query(
        policiesCollection,
        where("documentType", "==", "Policy"),
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data !== "load-all") {
        if (data?.["start-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          policiesQuery = query(
            policiesQuery,
            where("effectiveDate", "<=", data["end-date"])
          );
        if (program)
          policiesQuery = query(policiesQuery, where("program", "==", program));
      }
      const querySnapshot = await getDocs(policiesQuery);
      const updatedPolicyListPromises = querySnapshot.docs.map(
        async (policyDoc) => {
          const policyData = policyDoc.data();
          const { namedInsured, agencyID, ...rest } = policyData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              policyData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);

            const city = insuredDocSnapshot?.data()?.city
            const state = insuredDocSnapshot?.data()?.state
            let counties = []
            if (
              insuredDocSnapshot.exists()
              && city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state)
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse
                }
              } catch (error) {
                console.error("Error getting county: ", error)
              }
            }

            if (insuredDocSnapshot.exists()) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: policyDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );
      const transformAndDuplicate = async (infoArray) => {
        const glPoliciesCollection = collection(db, "gl-policies");
        const resultPromises = infoArray.map(async (info) => {      
          let glPoliciesQuery = query(
            glPoliciesCollection,
            where("documentType", "==", "Policy"),
            where("agencyID", "==", info?.agencyID),
            where("insuredID", "==", info?.insuredID)
          );

          if (data !== "load-all") {
            if (data?.["start-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", ">=", data["start-date"])
              );
            if (data?.["end-date"] && type === "effective-date")
              glPoliciesQuery = query(
                glPoliciesQuery,
                where("effectiveDate", "<=", data["end-date"])
              );
            if (info?.status)
              glPoliciesQuery = query(glPoliciesQuery, where("status", "==", info.status));
          }
          const glQuerySnapshot = await getDocs(glPoliciesQuery);
          const glPolicyData = glQuerySnapshot.docs.map((glPolicyDoc) => ({
            ...glPolicyDoc.data(),
            id: glPolicyDoc?.id,
            insuredInfo: info?.insuredInfo,
            agencyInfo: info?.agencyInfo,
            namedInsured: info?.namedInsured,
            program: info?.program,
            product: info?.product,
            IssuedDate: info?.IssuedDate,
            stateOfEntry: info?.stateOfEntry,
            coverageSingleLimit: info?.coverageSingleLimit,
            takeGL: true,
            takeAL: false,
          }));
          if (glPolicyData.length > 0) {
            return [
              ...glPolicyData,
              { ...info, takeGL: false, takeAL: true },
            ];
          } else {
            return [{ ...info, takeAL: true, takeGL: false }];
          }
        });
        const resolvedResults = await Promise.all(resultPromises);
        return resolvedResults.flat();
      };
      const updatedPolicyList = await Promise.all(updatedPolicyListPromises);
      const duplicatedPolicies = await transformAndDuplicate(
        updatedPolicyList.filter(Boolean)
      );
      const issuedDateEndosFiltered = filterPoliciesByIssuedDate(
        duplicatedPolicies,
        data["start-date"],
        data["end-date"]
      );
      if (!isBordereaux) {
        setItemList(issuedDateEndosFiltered);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return issuedDateEndosFiltered;
    } catch (error) {
      console.error("Error fetching policies: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

export const handleEndorsementMainSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  isBordereaux = false
) => {
  setLoading(true);
  if (
    !data["start-date"] &&
    !data["end-date"] &&
    data !== "load-all" &&
    !program
  ) {
    controlToast(true, "Select Load All or a date to search", "error");
    setLoading(false);
    return;
  }
  if (userInfo.agencyID) {
    try {
      const endoCollection = collectionGroup(db, "endorsements");
      let endoQuery = query(
        endoCollection,
        where("agencyID", "==", userInfo.agencyID)
      );
      if (data !== "load-all") {
        if (data?.["start-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", ">=", data["start-date"])
          );
        if (data?.["end-date"] && type === "effective-date")
          endoQuery = query(
            endoQuery,
            where("endoEffectiveDate", "<=", data["end-date"])
          );
      }
      const querySnapshot = await getDocs(endoQuery);
      const updatedEndoListPromises = querySnapshot.docs.map(
        async (endoDoc) => {
          const endoData = endoDoc.data();
          const { namedInsured, agencyID, ...rest } = endoData;
          if (agencyID) {
            const insuredDocRef = doc(
              db,
              "agencies",
              userInfo.agencyID,
              "insureds",
              endoData.insuredID
            );
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);
            
            const city = insuredDocSnapshot?.data()?.city
            const state = insuredDocSnapshot?.data()?.state
            let counties = []
            if (
              insuredDocSnapshot.exists()
              && city && state
            ) {
              try {
                const countiesResponse = await getCounty(city, state)
                if (Array.isArray(countiesResponse) && countiesResponse.length > 0) {
                  counties = countiesResponse
                }
              } catch (error) {
                console.error("Error getting county: ", error)
              }
            }

            if (
              insuredDocSnapshot.exists() &&
              (endoData.type !== "Add Additional Insureds" ||
                (endoData.type === "Add Additional Insureds" &&
                  endoData?.alPremiums?.GWP > 0)) &&
              (endoData.status === "Issued" ||
                endoData.status === "Approved") &&
              endoData.type !== "Add Driver" &&
              endoData.type !== "Add Drivers" &&
              endoData.type !== "Remove Driver" &&
              endoData.type !== "Remove Drivers" &&
              endoData.type !== "Exclude Driver" &&
              endoData.type !== "Exclude Drivers"
            ) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: endoDoc.id,
                namedInsured: companyName,
                agencyID,
                insuredInfo: {
                  ...insuredDocSnapshot.data(),
                  counties,
                },
                agencyInfo: agencyDocSnapshot.data(),
                ...rest,
              };
            }
          } else {
            console.error(
              "userInfo.agencyID is null or undefined. Skipping database operation."
            );
          }
          return null;
        }
      );
      const transformAndDuplicate1 = (info) => {
        return info.flatMap(async (item) => {
          const policyRef = doc(db, "policy-applications", item.policyID);
          let policyData = null;
          const policyDocDocSnapshot = await getDoc(policyRef);
          policyData = policyDocDocSnapshot?.data();
          const data = item["data"];
          if (data && policyData) {
            return {
              ...item,
              policyData
            };
          } else {
            return item;
          }
        });
      };

      const transformAndDuplicate2 = (info) => {
        return info.flatMap((item) => {
          if (item?.policyData?.glPolicyNo) {
            return [
              { ...item, takeGL: true, takeAL: false },
              { ...item, takeGL: false, takeAL: true },
            ];
          } else if (item?.policyData?.alPolicyNo) {
            return { ...item, takeAL: true };
          } else {
            return item;
          }
        });
      };

      const updatedEndoList = await Promise.all(updatedEndoListPromises);
      const duplicatedEndos1 = await Promise.all(transformAndDuplicate1(
        updatedEndoList.filter(Boolean)
      ))
      const duplicatedEndos2 = transformAndDuplicate2(duplicatedEndos1)
      let endoSolvedPromises = await Promise.all(duplicatedEndos2);
      if (program) {
        endoSolvedPromises = endoSolvedPromises.filter((endo) => {
          const programValue = itemProgram(endo?.policyData);
          return programValue === program;
        });
      }
      endoSolvedPromises = endoSolvedPromises.filter((endo) => {
        return Boolean(!Array.isArray(endo))
      });
      if (!isBordereaux) {
        setItemList(endoSolvedPromises);
        setStartDateUsed(data["start-date"]);
        setEndDateUsed(data["end-date"]);
      }
      return endoSolvedPromises;
    } catch (error) {
      console.error("Error fetching endorsements: ", error);
    } finally {
      if (!isBordereaux) {
        setLoading(false);
        setDidRequest(true);
      }
    }
  } else {
    if (!isBordereaux) {
      setLoading(false);
      setDidRequest(true);
    }
  }
};

export const handleBordereauxMainSearch = async (
  data,
  program,
  controlToast,
  setLoading,
  setItemList,
  setStartDateUsed,
  setEndDateUsed,
  setDidRequest,
  userInfo,
  type,
  bordereauxType
) => {
  try {
    const policies = await handlePolicyMainSearch(
      data,
      program,
      controlToast,
      setLoading,
      setItemList,
      setStartDateUsed,
      setEndDateUsed,
      setDidRequest,
      userInfo,
      type,
      "bordereaux",
      bordereauxType
    );
    if (policies) {
      const endos = await handleEndorsementMainSearch(
        data,
        program,
        controlToast,
        setLoading,
        setItemList,
        setStartDateUsed,
        setEndDateUsed,
        setDidRequest,
        userInfo,
        type,
        "bordereaux",
        bordereauxType
      );
      setItemList([...policies, ...endos]);
      setStartDateUsed(data["start-date"]);
      setEndDateUsed(data["end-date"]);
    }
  } catch (error) {
    console.error("Error fetching bordereaux: ", error);
  } finally {
    setLoading(false);
    setDidRequest(true);
  }
};