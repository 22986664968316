import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, TextField, Box } from '@mui/material';

const AttendeesAndAgents = ({ handleInputChange }) => {
    // Define sections and questions
    const sections = {
      attendees: [
        { id: "fullName1", label: "Full name:", type: "text" },
        { id: "experience1", label: "Experience:", type: "number" },
        { id: "phone1", label: "Phone:", type: "phone" },
        { id: "email1", label: "Email:", type: "email" },
      ],
      lossAndClaims: [
        { id: "fullName2", label: "Full name:", type: "text" },
        { id: "experience2", label: "Experience:", type: "number" },
        { id: "phone2", label: "Phone:", type: "phone" },
        { id: "email2", label: "Email:", type: "email" },
      ],
      thirdPartySafety: [
        { id: "name3", label: "Name:", type: "text" },
        { id: "timeOfDuty3", label: "Time of duty:", type: "number" },
        { id: "location3", label: "Location:", type: "text" },
      ],
      alcoholTestingAgency: [
        { id: "name4", label: "Name:", type: "text" },
        { id: "timeOnDuty4", label: "Time on duty:", type: "number" },
        { id: "location4", label: "Location:", type: "text" },
      ],
    };
  
    // State to manage responses
    const [responses, setResponses] = useState({
      fullName1: "",
      experience1: "",
      phone1: "",
      email1: "",
      fullName2: "",
      experience2: "",
      phone2: "",
      email2: "",
      name3: "",
      timeOfDuty3: "",
      location3: "",
      name4: "",
      timeOnDuty4: "",
      location4: "",
    });

    const replaceAndCapitalize = (str) => {
      return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
    }

  return (
    <Card sx={{ maxWidth: 800, margin: 'auto', padding: 2, marginBottom: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Attendees and Agents
        </Typography>
        <Grid container spacing={2}>
            <Grid item xs={12} >
            <Box sx={{ padding: 2 }}>
      {Object.entries(sections).map(([sectionKey, questions]) => (
        <Box key={sectionKey} sx={{ marginBottom: 4 }}>
          <Typography variant="h6" gutterBottom>
            {replaceAndCapitalize(sectionKey)}
          </Typography>
          <Grid container spacing={2}>
            {questions.map(({ id, label, type }) => (
              <Grid item xs={12} key={id}>
                <TextField
                  label={label}
                  name={id}
                  type={type}
                  value={responses[id]}
                  onChange={(e) => handleInputChange(id, e.target.value)}
                  fullWidth
                  multiline={type === 'textarea'}
                  rows={type === 'textarea' ? 4 : 1}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AttendeesAndAgents;
